import React, { ComponentType } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';

export const loginRequired = (
  Component: ComponentType<RouteComponentProps>,
  loggedIn: boolean
) => (props: RouteComponentProps) =>
  loggedIn ? (
    <Component {...props} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        // Store where user was before being sent to login screen, so
        // that once login is completed, they user is redirected back
        // to the same screen
        state: { referrer: props.location },
      }}
      {...props}
    />
  );
