import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { Spinner } from '../components/Spinner';
import { Context } from '../state';

export class LoginComplete extends React.Component<Props, State> {
  public static contextType = Context;
  public readonly state: State = {};
  public context!: React.ContextType<typeof Context>;
  private timer?: NodeJS.Timeout;

  public async componentDidMount() {
    const { location } = this.props;
    const { auth } = this.context;

    if (/access_token|id_token|error/.test(location.hash)) {
      try {
        const { redirectUrl: redirect } = await auth.getCredentialsFromHash();
        this.setState({ redirect });
      } catch ({ error }) {
        if (error === 'unauthorized') {
          // For an unauthorized user, we'll let a user read the error screen for
          // 5 seconds and then do them the favor of logging them out automatically
          this.timer = setInterval(auth.logout, 5000);
          return this.setState({ error: 'Unauthorized, logging you out...' });
        }
        return this.setState({
          error:
            error ||
            'Unknown error, please contact Cadasta for support (help@cadasta.org)',
        });
      }
    } else {
      this.setState({ redirect: '/' });
    }
  }

  public componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  public render() {
    return (
      <div>
        {this.state.redirect && <Redirect to={this.state.redirect} />}
        {this.state.error ? (
          <>
            Unfortunately, login has failed: <code>{this.state.error}</code>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}
interface Props extends RouteComponentProps {}
interface State {
  redirect?: string;
  error?: string;
}
