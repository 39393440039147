import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Alert } from 'reactstrap';

import { ProjectMetrics } from '../../services';
import { Context } from '../../state';
import { ProjectForm } from './ProjectForm';

export class ProjectUpdate extends Component<
  RouteComponentProps<{ projectId: string }, {}, { name: string, status: string }>,
  State
> {
  public static contextType = Context;
  public readonly state: State = {};
  public context!: React.ContextType<typeof Context>;

  public componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        refererState: {
          status: this.props.location.state.status,
        },
      });
    }

    if (!this.state.status) {
      this.fetch();
    }
  }

  public render() {
    const { data, status, error, refererState } = this.state;

    return (
      <div>
        <h2>Update: {data && data.name}</h2>

        {error && (
          <Alert color="danger">
            <strong>
              {({
                LOAD_FAILED: 'Loading data failed',
                UPDATE_FAILED: 'Updating data failed',
              } as { [key: string]: string })[status!] || 'Unexpected error'}
            </strong>{' '}
            {error}
          </Alert>
        )}

        {status === 'LOADING' && <Alert color="info">Loading...</Alert>}
        {status === 'UPDATE_SUCCESS' && (
          <Alert color="success">Project successfully updated</Alert>
        )}

        {refererState && refererState.status === 'CREATE_SUCCESS' &&
          <Alert color="success">Project successfully created</Alert>
        }

        <ProjectForm submitForm={this.submitForm} data={data} />
      </div>
    );
  }

  private fetch = async () => {
    this.setState({ status: 'LOADING' });
    try {
      const data = await this.context.api.projectMetrics.get(
        this.props.match.params.projectId
      );
      this.setState({
        data,
        error: undefined,
        status: 'LOAD_SUCCESS',
      });
    } catch (error) {
      this.setState({
        data: undefined,
        error: typeof error === 'string' ? error : error.message,
        status: 'LOAD_FAILED',
      });
    }
  }

  private submitForm = (data: ProjectMetrics) => {
    this.context.api.projectMetrics.update(this.props.match.params.projectId, data)
      .then(() => {
        this.setState({
          error: undefined,
          refererState: undefined,
          status: 'UPDATE_SUCCESS',
        });
      })
      .catch((error: Error) => {
        this.setState({
          error: typeof error === 'string' ? error : error.message,
          refererState: undefined,
          status: 'UPDATE_FAILED',
        });
      })
      .then(() =>  window.scrollTo(0, 0));
  }
}
interface State {
  status?:
    | 'LOADING'
    | 'LOAD_SUCCESS'
    | 'LOAD_FAILED'
    | 'UPDATE_SUCCESS'
    | 'UPDATE_FAILED';
  data?: ProjectMetrics;
  error?: string;
  response?: string;
  refererState?: {
    status?: string
  };
}
