import React from 'react';

export class ErrorBoundary extends React.Component<{}, State> {
  public readonly state: State = {};

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  public render() {
    return this.state.errorInfo ? (
      <div className="p-2">
        <h2>Something went wrong.</h2>
        <details style={{ whiteSpace: 'pre-wrap' }}>
          {this.state.error && this.state.error.toString()}
          <br />
          {this.state.errorInfo.componentStack}
        </details>
      </div>
    ) : (
      this.props.children
    );
  }
}

interface State {
  error?: Error;
  errorInfo?: React.ErrorInfo;
}
