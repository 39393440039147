import React from 'react';

import { GenericChart, GenericChartProps } from '../charts/GenericChart';
import { DataItem } from '../charts/interfaces';

const spec = (lineColor: string, data: DataItem[]) => ({
  $schema: 'https://vega.github.io/schema/vega/v5.json',
  autosize: 'fit',
  axes: [
    { orient: 'bottom', scale: 'x', tickCount: 5 },
    { orient: 'left', scale: 'y', tickCount: 5, offset: 6 },
  ],
  data: [
    {
      format: { parse: { category: 'date' } },
      name: 'series',
      values: data,
    },
  ],
  marks: [
    {
      encode: {
        enter: {
          interpolate: { value: 'linear' },
          stroke: { value: lineColor },
          strokeCap: { value: 'round' },
          strokeJoin: { value: 'round' },
          strokeWidth: { value: 5 },
          tooltip: {
            signal: 'timeFormat(datum.category, \'%b %Y\') + \': \' + datum.value',
          },
          x: { scale: 'x', field: 'category' },
          y: { scale: 'y', field: 'value' },
        },
        hover: {
          strokeOpacity: { value: 0.5 },
        },
        update: {
          strokeOpacity: { value: 1 },
        },
      },
      from: { data: 'series' },
      type: 'line',
    },
  ],
  padding: { left: 5, right: 5, top: 5, bottom: 5 },
  scales: [
    {
      domain: { data: 'series', field: 'category' },
      name: 'x',
      range: 'width',
      type: 'time',
    },
    {
      domain: { data: 'series', field: 'value' },
      name: 'y',
      nice: true,
      range: 'height',
      type: 'linear',
      zero: true,
    },
  ],
});

export default ({ lineColor, data, ...props }: Props) => {
  return (
    <GenericChart
      spec={spec(lineColor, data || [])}
      data={
        data === undefined
          ? undefined
          : data
              .filter(({ category }) => category.includes('-12-'))
              .map(({ category, value }) => ({
                category: category.split('-')[0],
                value,
              }))
      }
      {...props}
    />
  );
};

interface Props extends GenericChartProps {
  lineColor: string;
}
