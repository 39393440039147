import React from 'react';
import { Col, Row } from 'reactstrap';

import { AggBucket, GlobalMetrics } from '../../../services';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderSection,
  CardHeaderStep,
  StatsBox,
  StatsBoxHeader,
  StatsBoxValue,
} from '../layout';
import { fallback, fmtNumber } from '../numbers';
import { ProjectsMetricAggregations } from './GlobalDashboard';
import { WorldMap } from './WorldMap';

export const WhereStats: React.SFC<Props> = ({
  globalData: global,
  projectsData: projects,
}) => (
  <Row id="where">
    <Col sm="12" className="my-4">
      <Card>
        <CardHeaderSection>
          <CardHeader>
            <CardHeaderStep>02</CardHeaderStep>
            Where
          </CardHeader>
          Global Map of Cadasta Projects
        </CardHeaderSection>
        <CardBody className="px-0 pt-0">
          <Row>
            <Col sm="12" md="9">
              <WorldMap projectsData={projects} />
            </Col>
            <Col sm="12" md="3">
              <ul className="stats-list list-unstyled">
                <StatsBox tag="li" className="pr-4 text-right">
                  <StatsBoxHeader>Countries</StatsBoxHeader>
                  <StatsBoxValue>
                    {fallback(
                      projects && fmtNumber(projects.countries.buckets.length)
                    )}
                  </StatsBoxValue>
                </StatsBox>
                <StatsBox tag="li" className="pr-4 text-right">
                  <StatsBoxHeader>Government Partners</StatsBoxHeader>
                  <StatsBoxValue>
                    {fallback(
                      projects && projects.activeGovEngagement.buckets.length
                        ? fmtNumber(
                            (
                              projects.activeGovEngagement.buckets.find(
                                ({ key: isTrue }) => !!isTrue
                              ) || ({} as AggBucket)
                            ).doc_count || 0
                          )
                        : undefined
                    )}
                  </StatsBoxValue>
                </StatsBox>
                <StatsBox tag="li" className="pr-4 text-right">
                  <StatsBoxHeader>Communities</StatsBoxHeader>
                  <StatsBoxValue>
                    {fallback(
                      global && fmtNumber(global.communitiesDocumented)
                    )}
                  </StatsBoxValue>
                </StatsBox>
                <StatsBox tag="li" className="pr-4 text-right">
                  <StatsBoxHeader>Parcels</StatsBoxHeader>
                  <StatsBoxValue>
                    {fallback(projects && fmtNumber(projects.numParcels.value))}
                  </StatsBoxValue>
                </StatsBox>
                <StatsBox tag="li" className="pr-4 text-right">
                  <StatsBoxHeader>Hectares</StatsBoxHeader>
                  <StatsBoxValue>
                    {fallback(
                      projects && fmtNumber(projects.numHectares.value)
                    )}
                  </StatsBoxValue>
                </StatsBox>
              </ul>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

interface Props {
  globalData?: GlobalMetrics;
  projectsData?: ProjectsMetricAggregations;
}
