import { ScaleLinear } from 'd3-scale';
import React from 'react';
import { Col, Row } from 'reactstrap';

export const MapLegend = ({ scale }: Props) => (
  <div
    style={{
      backgroundColor: 'white',
      bottom: '2em',
      position: 'absolute',
      right: 0,
    }}
    className="px-2 pt-0 pb-1 border border-bottom-0 border-right-0"
  >
    <Row>
      <Col className="text-truncate">
        <small className="text-center text-tiny text-underline">
          Project Count
        </small>
      </Col>
    </Row>
    <Row
      noGutters
      style={{
        background: `linear-gradient(0.25turn, ${scale.range()[0]}, ${
          scale.range()[1]
        })`,
        height: 15,
        width: '100%',
      }}
    />
    <Row>
      <Col>
        <span style={{ fontSize: '.65em' }} className="float-left">
          {scale.domain()[0]}
        </span>
        <span style={{ fontSize: '.65em' }} className="float-right">
          {scale.domain()[1]}
        </span>
      </Col>
    </Row>
  </div>
);

interface Props {
  scale: ScaleLinear<string, string>;
}
