import React from 'react';
import { FaInfoCircle } from 'react-icons/fa'; // tslint:disable-line:no-submodule-imports
import { Col, Row } from 'reactstrap';

import { GlobalMetrics } from '../../../services';
import PieChart from '../charts/PieChart';
import {
  Card,
  CardBody,
  CardGroup,
  CardHeader,
  CardHeaderSection,
  CardHeaderStep,
  InnerCard,
  StatsBox,
  StatsBoxHeader,
  StatsBoxValue,
} from '../layout';
import { Modal } from '../Modal';
import { fallback, fmtNumber } from '../numbers';
import { NumberOfUsersAggregation, ProjectsMetricAggregations } from './GlobalDashboard';
import UsersChart from './UsersChart';

const setDate = (dateStr: string) => {
  const d = new Date(dateStr);
  d.setMonth(d.getMonth() + 2);
  return d.toISOString();
};

export const QuickStats: React.SFC<Props> = ({
  globalData: global,
  projectsData: projects,
  numberOfUsersData: numberOfUsers,
}) => (
  <Row id="quick">
    <Col sm="12" className="my-4">
      <Card>
        <CardHeaderSection>
          <CardHeader>
            <CardHeaderStep>01</CardHeaderStep>
            Quick Stats
          </CardHeader>
          Key Cadasta Metrics
        </CardHeaderSection>
        <CardBody>
          <Row>
            <Col sm="12" md="3">
              <ul className="stats-list list-unstyled pt-4">
                <StatsBox tag="li" className="pl-4">
                  <StatsBoxHeader>
                    People on the platform
                    <Modal
                      link={
                        <sup className="pl-1">
                          <FaInfoCircle />
                        </sup>
                      }
                      title={'People on the platform...'}
                      body={
                        <>
                          <p>
                            Because the household data collection varies by
                            project, this metric is an estimate using actual
                            data and an average number of people per household
                            per global figures ({''}
                            <a
                              href={
                                'http://www.un.org/en/development/desa/population/' +
                                'publications/pdf/popfacts/PopFacts_2017-2.pdf'
                              }
                            >
                              as detailed here
                            </a>
                            ). For more information on our metrics dashboard
                            please contact{' '}
                            <a href="mailto:info@cadasta.org">
                              info@cadasta.org
                            </a>
                            .
                          </p>
                        </>
                      }
                    />
                  </StatsBoxHeader>
                  <StatsBoxValue>
                    {fallback(
                      global &&
                        projects &&
                        fmtNumber(
                          global.peopleInHousehold *
                            projects.numHouseholds.value
                        )
                    )}
                  </StatsBoxValue>
                </StatsBox>
                <StatsBox tag="li" className="pl-4">
                  <StatsBoxHeader>Households documented</StatsBoxHeader>
                  <StatsBoxValue>
                    {fallback(
                      projects && fmtNumber(projects.numHouseholds.value)
                    )}
                  </StatsBoxValue>
                </StatsBox>
                <StatsBox tag="li" className="pl-4">
                  <StatsBoxHeader>Land Documents Issued</StatsBoxHeader>
                  <StatsBoxValue>
                    {fallback(global && fmtNumber(global.governmentLandTitles))}
                  </StatsBoxValue>
                </StatsBox>
                <StatsBox tag="li" className="pl-4">
                  <StatsBoxHeader>Projects</StatsBoxHeader>
                  <StatsBoxValue>
                    {fallback(
                      projects && fmtNumber(projects.numProjects.value)
                    )}
                  </StatsBoxValue>
                </StatsBox>
                <StatsBox tag="li" className="pl-4">
                  <StatsBoxHeader>Partners</StatsBoxHeader>
                  <StatsBoxValue>
                    {fallback(global && fmtNumber(global.partnersCount))}
                  </StatsBoxValue>
                </StatsBox>
              </ul>
            </Col>
            <Col sm="12" md="9">
              <CardGroup>
                <InnerCard>
                  <UsersChart
                    label="Users by Year"
                    data={
                      numberOfUsers === undefined
                        ? undefined
                        : numberOfUsers.map(({ key_as_string, value }) => ({
                            category: setDate(key_as_string),
                            value,
                          }))
                    }
                    lineColor="#e04b59"
                    showNumbers
                    percentsOff
                    legendColorsOff
                  />
                </InnerCard>
                <InnerCard>
                  <PieChart
                    donut
                    label="Gender"
                    info="Gender data was collected in 75% of projects"
                    data={
                      projects === undefined
                        ? undefined
                        : [
                            {
                              category: 'Women',
                              value: projects.numWomen.value,
                            },
                            {
                              category: 'Men',
                              value: projects.numMen.value,
                            },
                          ]
                    }
                  />
                </InnerCard>
              </CardGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

interface Props {
  globalData?: GlobalMetrics;
  projectsData?: ProjectsMetricAggregations;
  numberOfUsersData?: NumberOfUsersAggregation;
}
