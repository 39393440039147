import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import { Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { Global } from './Global';
import { ProjectCreate } from './ProjectCreate';
import { ProjectList } from './ProjectList';
import { ProjectUpdate } from './ProjectUpdate';

const GLOBAL_PATH = 'global-metrics';
const PROJECT_PATH = 'projects';

export const Admin: React.SFC<RouteComponentProps> = ({ match }) => (
  <Container>
    <Row>
      <Col sm="3">
        <Nav className="ml-auto" vertical>
          <NavItem>
            <NavLink tag={Link} to={`${match.path}/${GLOBAL_PATH}`}>
              Global Metrics
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to={`${match.path}/${PROJECT_PATH}`}>
              Projects
            </NavLink>
          </NavItem>
        </Nav>
      </Col>
      <Col sm="9">
        <Switch>
          <Route path={`${match.path}/${GLOBAL_PATH}`} component={Global} />
          <Route
            exact
            path={`${match.path}/${PROJECT_PATH}`}
            component={ProjectList}
          />
          <Route
            exact
            path={`${match.path}/${PROJECT_PATH}/new`}
            component={ProjectCreate}
          />
          <Route
            path={`${match.path}/${PROJECT_PATH}/:projectId`}
            component={ProjectUpdate}
          />
        </Switch>

      </Col>
    </Row>
  </Container>
);
