/*
We utilize the React context API to manage the application's state. The
context is to be provided at the root of the application, enabling any
class component to subscribe to the context by setting the 'contextType'
property to the Context object exported from this file.
*/
import React from 'react';
import { ApiService, AuthService } from './services';

export const Context = React.createContext<AppState>(({} as any) as AppState);

export interface AppState {
  api: ApiService;
  auth: AuthService;
  setHeader: (title: string, updatedDate?: string) => void;
}
