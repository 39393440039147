export default [
  '#0e537e',
  '#3db3e1',
  '#69d1d9',
  '#7ca721',
  '#9dcd28',
  '#8a143e',
  '#ea3030',
  '#e04b59',
  '#fa7922',
  '#fdb230',
];

export const otherColor = '#b4b4b4';
