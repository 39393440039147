import { Search } from 'history';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Alert,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Table,
} from 'reactstrap';
import { useDebounce } from 'use-debounce';

import {
  PaginationLinks,
  parseQuerystring,
} from '../../components/PaginationLinks';
import { ParsedApiManyResponse, ProjectMetrics } from '../../services';
import { Context } from '../../state';

export const ProjectList = ({
  location: { search: getParams },
  match: { path },
}: Props) => {
  const pageSize = 20;
  const page = getPage(getParams);

  const { api } = useContext(Context);
  const [status, setStatus] = useState<Status>(undefined);
  const [data, setData] = useState<Data>(undefined);
  const [error, setError] = useState<undefined | string>(undefined);

  const [rawSearch, setRawSearch] = useState<string>('');
  const [search] = useDebounce(rawSearch, 350);
  const handleRawSearch = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setRawSearch(e.currentTarget.value);
  };

  useEffect(() => {
    setStatus('LOADING');
    api.projectMetrics
      .list({
        fields: ['name', 'addedOn'],
        page,
        search,
        size: pageSize,
      })
      .then((response) => {
        setData(response);
        setError(undefined);
        setStatus('LOAD_SUCCESS');
      })
      .catch((err: Error) => {
        setData(undefined);
        setError(typeof err === 'string' ? err : err.message);
        setStatus('LOAD_FAILED');
      });
  },        [page, search]);

  return (
    <div>
      <h2>Projects</h2>

      <nav className="justify-content-between d-flex align-items-start">
        <Button tag={Link} to="/admin/projects/new">
          Add project
        </Button>
        <PaginationLinks
          currentPage={page}
          total={data ? data.total : 0}
          pageSize={pageSize}
          currentSearch={getParams}
        />
      </nav>
      <InputGroup>
        <InputGroupAddon addonType="prepend">🔍</InputGroupAddon>
        <Input
          value={rawSearch}
          onChange={handleRawSearch}
          placeholder="Search by project name, description, partner, country code"
        />
      </InputGroup>
      {error ? (
        <Alert color="danger">
          <strong>
            {({
              LOAD_FAILED: 'Loading data failed',
            } as { [key: string]: string })[status!] || 'Unexpected error'}
          </strong>{' '}
          {error}
        </Alert>
      ) : status === 'LOADING' ? (
        <Alert color="info">Loading...</Alert>
      ) : data ? (
        <>
          <Table size="sm">
            <thead>
              <tr>
                <th>Name</th>
                <th>Created On</th>
              </tr>
            </thead>
            <tbody>
              {data.hits.map(({ id, name, addedOn }) => (
                <tr key={id}>
                  <td>
                    <Link
                      to={{
                        pathname: `${path}/${id}`,
                        state: { name },
                      }}
                    >
                      {name}
                    </Link>
                  </td>
                  <td>{addedOn}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        'No Data'
      )}
    </div>
  );
};

function getPage(search: Search): number {
  try {
    return Number(parseQuerystring(search).page || '1');
  } catch (err) {
    console.error(err);
    return 1;
  }
}
interface Props extends RouteComponentProps<PaginationRouteProps> {}
interface PaginationRouteProps {
  page?: string;
}
type Status = undefined | 'LOADING' | 'LOAD_SUCCESS' | 'LOAD_FAILED';
type Data = undefined | ParsedApiManyResponse<ProjectMetrics>;
