import React, { Component } from 'react';
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  InputProps,
  Label,
} from 'reactstrap';
import { GlobalMetrics } from '../../services/Api.interfaces';
import { Context } from '../../state';

export class Global extends Component<{}, State> {
  public static contextType = Context;
  public readonly state: State = {};
  public context!: React.ContextType<typeof Context>;

  public componentDidMount() {
    if (!this.state.status) {
      this.fetch();
    }
  }

  public render() {
    const formFieldProps: InputProps = {
      min: 0,
      onChange: this.onChange,
      placeholder: '0',
      type: 'number',
    };

    return (
      <div>
        <h2>Global Metrics</h2>

        {this.state.error && (
          <Alert color="danger">
            <strong>
              {({
                LOAD_FAILED: 'Loading data failed',
                UPDATE_FAILED: 'Updating data failed',
              } as { [key: string]: string })[this.state.status!] ||
                'Unexpected error'}
            </strong>{' '}
            {this.state.error}
          </Alert>
        )}

        {this.state.status === 'LOADING' && (
          <Alert color="info">Loading...</Alert>
        )}
        {this.state.status === 'UPDATE_SUCCESS' && (
          <Alert color="success">Global metrics successfully updated</Alert>
        )}
        {this.state.data && (
          <Form onSubmit={this.onSubmit}>
            <p>
              Last updated on {this.state.data.addedOn} by{' '}
              {this.state.data.addedBy}
            </p>
            <FormGroup>
              <Label for="partnersCount">Number of partners</Label>
              <Input
                name="partnersCount"
                id="partnersCount"
                value={this.state.data.partnersCount}
                {...formFieldProps}
              />
            </FormGroup>
            <FormGroup>
              <Label for="womenTrained">
                Number of women trained to use Cadasta’s tools
              </Label>
              <Input
                name="womenTrained"
                id="womenTrained"
                value={this.state.data.womenTrained}
                required
                {...formFieldProps}
              />
            </FormGroup>
            <FormGroup>
              <Label for="menTrained">
                Number of men trained to use Cadasta’s tools
              </Label>
              <Input
                name="menTrained"
                id="menTrained"
                value={this.state.data.menTrained}
                required
                {...formFieldProps}
              />
            </FormGroup>
            <FormGroup>
              <Label for="governmentLandTitles">
                Number of government land titles issued
              </Label>
              <Input
                name="governmentLandTitles"
                id="governmentLandTitles"
                value={this.state.data.governmentLandTitles}
                required
                {...formFieldProps}
              />
            </FormGroup>
            <FormGroup>
              <Label for="communitiesDocumented">
                Number of communities documented
              </Label>
              <Input
                name="communitiesDocumented"
                id="communitiesDocumented"
                value={this.state.data.communitiesDocumented}
                required
                {...formFieldProps}
              />
            </FormGroup>
            <FormGroup>
              <Label for="peopleInHousehold">Number of people in household</Label>
              <Input
                name="peopleInHousehold"
                id="peopleInHousehold"
                value={this.state.data.peopleInHousehold}
                required
                step={0.1}
                {...formFieldProps}
              />
            </FormGroup>
            <FormGroup>
              <Label for="netPromoterScore">Net Promoter Score</Label>
              <Input
                name="netPromoterScore"
                id="netPromoterScore"
                value={this.state.data.netPromoterScore}
                required
                {...formFieldProps}
              />
            </FormGroup>
            <FormGroup>
              <Label for="numberUsers">Number of Users</Label>
              <Input
                name="numberUsers"
                id="numberUsers"
                value={this.state.data.numberUsers || 0}
                required
                {...formFieldProps}
              />
            </FormGroup>

            <Button>Submit</Button>
          </Form>
        )}
      </div>
    );
  }

  private fetch = async () => {
    this.setState({ status: 'LOADING' });
    try {
      const data = await this.context.api.globalMetrics.latest();
      this.setState({
        data,
        error: undefined,
        status: 'LOAD_SUCCESS',
      });
    } catch (error) {
      this.setState({
        data: undefined,
        error: typeof error === 'string' ? error : error.message,
        status: 'LOAD_FAILED',
      });
    }
  }

  private onSubmit = async (event: any) => {
    event.preventDefault();
    this.context.api.globalMetrics
      .write(this.state.data!)
      .then((data) =>
        this.setState({
          data,
          status: 'UPDATE_SUCCESS',
        })
      )
      .catch((error) => {
        this.setState({
          error: typeof error === 'string' ? error : error.message,
          status: 'UPDATE_FAILED',
        });
      });
  }

  private onChange = ({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const value = currentTarget.getAttribute('step')
      ? parseFloat(currentTarget.value)
      : parseInt(currentTarget.value, 10);
    this.setState({
      data: {
        ...(this.state.data || {}),
        [currentTarget.name]: value,
      } as GlobalMetrics, // tslint:disable-line:no-object-literal-type-assertion
      error: undefined,
    });
  }
}
interface State {
  status?:
    | 'LOADING'
    | 'LOAD_SUCCESS'
    | 'LOAD_FAILED'
    | 'UPDATE_SUCCESS'
    | 'UPDATE_FAILED';
  data?: GlobalMetrics;
  error?: string;
}
