import React from 'react';
import { Col, Row } from 'reactstrap';

import { ProjectMetrics } from '../../../services';
import { getCountryName } from '../../utils';
import {
  StatsBox,
  StatsBoxData,
  StatsBoxHeader,
  StatsBoxValue,
} from '../layout';
import { fmtNumber } from '../numbers';
import ProjectMap from './ProjectMap';
export default (props: Props) => {
  const {
    country: countryCode,
    geometry,
    projectData: { 
      numberOfParcels,
      numberOfHectares,
      numberOfHouseholds,
    },
  } = props.project;

  return (
    <Row>
      <ProjectMap geometry={geometry} />
      <Col sm="12" md="3">
        <ul className="stats-list list-unstyled">
          <StatsBox tag="li" className="pr-4 text-right">
            <StatsBoxHeader>Country</StatsBoxHeader>
            <StatsBoxValue>{getCountryName(countryCode)}</StatsBoxValue>
          </StatsBox>
          {numberOfHouseholds > 0 &&
            <StatsBox tag="li" className="pr-4 text-right">
              <StatsBoxHeader>Number of Households</StatsBoxHeader>
              <StatsBoxValue>
                {fmtNumber(numberOfHouseholds)}
              </StatsBoxValue>
            </StatsBox>
          }
          {numberOfParcels > 0 &&
            <StatsBox tag="li" className="pr-4 text-right">
              <StatsBoxHeader>Parcels</StatsBoxHeader>
              <StatsBoxValue>
                {fmtNumber(numberOfParcels)}
              </StatsBoxValue>
            </StatsBox>
          }
          {numberOfHectares > 0 &&
            <StatsBox tag="li" className="pr-4 text-right">
              <StatsBoxHeader>Hectares</StatsBoxHeader>
              <StatsBoxValue>
                {fmtNumber(numberOfHectares)}
              </StatsBoxValue>

              {numberOfHectares && numberOfParcels &&
                <StatsBoxData>
                  Avg Parcel Size{' '}
                  <strong>
                    {
                      fmtNumber(
                        numberOfHectares / numberOfParcels || 0,
                        2
                      )
                    }
                  </strong>
                </StatsBoxData>
              }
            </StatsBox>
            
          }
        </ul>
      </Col>
    </Row>
  );
};

interface Props {
  project: ProjectMetrics;
}
