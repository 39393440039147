import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

class GoogleAnalytics extends React.Component<RouteComponentProps> {
  public componentWillUpdate({ location, history }: RouteComponentProps) {
    // @ts-ignore
    const gtag = window.gtag;

    if (location.pathname === this.props.location.pathname) {
      // don't log identical link clicks (nav links likely)
      return;
    }

    if (history.action === 'PUSH' && typeof(gtag) === 'function') {
      gtag('config', 'UA-91010913-5', {
        page_location: window.location.href,
        page_path: location.pathname,
        page_title: document.title,
      });
    }
  }

  public render() {
    return null;
  }
}

export default withRouter(GoogleAnalytics);
