import React from 'react';
import './layout.scss';

export const Card: React.SFC<React.HTMLProps<HTMLDivElement>> = ({
  children,
  className = '',
}) => <div className={`card ${className ? className : ''}`}>{children}</div>;

export const CardGroup: React.SFC<React.HTMLProps<HTMLDivElement>> = ({
  children,
  className = '',
}) => <div className={`card-group ${className}`}>{children}</div>;

export const CardHeaderSection: React.SFC<React.HTMLProps<HTMLDivElement>> = ({
  children,
  className = 'pb-3 px-4',
}) => (
  <div className={`card-header section-header ${className}`}>{children}</div>
);

export const CardHeader: React.SFC<
  React.HTMLProps<HTMLDivElement> & { tag?: string }
> = ({ children, tag = 'h2', className = 'm-0' }) => {
  const Tag: React.ComponentClass<React.HTMLProps<HTMLDivElement>> = tag as any;
  return <Tag className={className}>{children}</Tag>;
};

export const CardHeaderStep: React.SFC = ({ children }) => (
  <span className="float-left pr-3 num">{children}</span>
);

export const CardBody: React.SFC<React.HTMLProps<HTMLDivElement>> = ({
  children,
  className = 'px-0 pt-2',
}) => <div className={`card-body ${className}`}>{children}</div>;

export const InnerCard: React.SFC<React.HTMLProps<HTMLDivElement>> = ({
  children,
  className = '',
}) => <div className={`card shadow-none p-4 ${className}`}>{children}</div>;

export const InnerCardBody: React.SFC = ({ children }) => (
  <div className="card-body pt-0">{children}</div>
);

export const StatsBox: React.SFC<React.HTMLProps<HTMLDivElement> & TagProp> = ({
  children,
  className = '',
  tag = 'div',
}) => {
  const Tag: React.ComponentClass<React.HTMLProps<HTMLDivElement>> = tag as any;
  return <Tag className={`stats-box px-0 ${className}`}>{children}</Tag>;
};

export const StatsBoxHeader: React.SFC = ({ children }) => (
  <h5 className="stats-label mb-0">{children}</h5>
);

export const StatsBoxValue: React.SFC = ({ children }) => (
  <div className="stats-value">{children}</div>
);

export const StatsBoxData: React.SFC = ({ children }) => (
  <div className="stats-data">{children}</div>
);

export const ChartInfo: React.SFC = ({ children }) => (
  <p className="chart-msg small border-top py-2">{children}</p>
);

interface TagProp {
  tag?: string;
}
