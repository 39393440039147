import React from 'react';

import { GenericChart, GenericChartProps } from './GenericChart';

const defaultWidth = 200;
const defaultHeight = 200;

const spec = (innerRadius: number = 0, total: number) => ({
  $schema: 'https://vega.github.io/schema/vega/v4.json',
  autosize: {
    resize: 'true',
    type: 'fit',
  },
  data: [
    {
      name: 'table',
      transform: [
        {
          field: 'value',
          type: 'pie',
        },
      ],
    },
  ],
  height: defaultHeight,
  marks: [
    {
      encode: {
        enter: {
          endAngle: { field: 'endAngle' },
          fill: { field: 'color' },
          innerRadius: { value: innerRadius },
          outerRadius: { signal: 'width / 2' },
          startAngle: { field: 'startAngle' },
          stroke: { value: '#fff' },
          tooltip: {
            signal: `datum.category + \': \' + format(datum.value / ${total}, \'0.1%\')`,
          },
          x: { signal: 'width / 2' },
          y: { signal: 'height / 2' },
        },
        hover: {
          fillOpacity: { value: 0.8 },
        },
        update: {
          fillOpacity: { value: 1 },
        },
      },
      from: { data: 'table' },
      type: 'arc',
    },
  ],
  scales: [
    {
      domain: { data: 'table', field: 'category' },
      name: 'color',
      range: { scheme: 'paired' },
      type: 'ordinal',
    },
  ],
  width: defaultWidth,
});

export default ({ donut = false, ...props }: Props) => {
  const total = (props.data || []).reduce((sum, { value }) => sum + value, 0);
  return <GenericChart spec={spec(donut ? 60 : 0, total)} isPie {...props} />;
};

interface Props extends GenericChartProps {
  donut?: boolean;
}
