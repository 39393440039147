import colors, { otherColor } from './colors';
import { ColoredDataItem, DataItem } from './interfaces';

export const assignColors = (
  data: DataItem[],
  customColors: string[] = []
): ColoredDataItem[] => {
  if (customColors.length && customColors.length < data.length) {
    throw new Error(`Not enough custom colors supplied. Got ${customColors.length}, data needs ${data.length}`);
  }

  const useColors = customColors.length ? customColors : colors;
  return data.map((datum, i) => ({
    ...datum,
    color: useColors[i],
  }));
};

export const prepChartData = (
  rawData: DataItem[],
  maxFields?: number,
  sorted?: boolean,
  customColors: string[] = []
): ColoredDataItem[] => {
  let data = sorted ? rawData.sort((a, b) => b.value - a.value) : rawData;

  data = data.filter((x) => x.value > 0);

  const tooManyFields = maxFields && data.length > maxFields;
  data = tooManyFields ? data.slice(0, maxFields! - 1) : data;
  const coloredData = assignColors(data, customColors);

  if (tooManyFields) {
    coloredData.push({
      category: 'Other',
      color: otherColor,
      value: rawData.reduce(
        (sum: number, x: DataItem, i: number) => sum + (i >= maxFields! - 1 ? x.value : 0), 0
      ),
    });
  }
  return coloredData;
};
