import { ScaleLinear } from 'd3-scale';
import React from 'react';
import { Motion, spring } from 'react-motion';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps';

import { AggBucket } from '../../../../services';
import { getCountryName } from '../../../utils';
import world from './world-countries.json';
import { State } from './WorldMap';

export const MapSvg = ({
  scale,
  defaultCenter: [xDefault, yDefault],
  center,
  zoom,
  buckets,
  onClick,
  countByCountry,
}: Props) => (
  <Motion
    defaultStyle={{
      x: xDefault,
      y: yDefault,
      zoom,
    }}
    style={{
      x: spring(center[0], { stiffness: 125, damping: 20 }),
      y: spring(center[1], { stiffness: 125, damping: 20 }),
      zoom: spring(zoom, { stiffness: 125, damping: 20 }),
    }}
  >
    {({ zoom: tmpZoom, x, y }) => (
      <ComposableMap
        projectionConfig={{
          scale: 185,
        }}
        style={{
          height: 'auto',
          width: '100%',
        }}
      >
        <ZoomableGroup center={[x, y]} zoom={tmpZoom}>
          {buckets.length && (
            <Geographies geography={world}>
              {(geographies, projection) =>
                (geographies as GeographyObject[]).map((geography, i) => {
                  const countryCode = geography.properties['Alpha-2'];
                  const value = countByCountry[countryCode];
                  const line = {
                    outline: 'none',
                    stroke: 'white',
                    strokeWidth: 0.5,
                  };
                  return (
                    <Geography
                      key={i}
                      geography={geography}
                      projection={projection}
                      onClick={onClick(
                        countryCode,
                        countryCode ? getCountryName(countryCode) : countryCode
                      )}
                      style={{
                        default: {
                          ...line,
                          fill: value === undefined ? '#E8E8E8' : scale(value),
                        },
                        hover: {
                          ...line,
                          fill:
                            value === undefined ? '#D8D8D8' : scale(value * 1.5),
                        },
                        pressed: {
                          ...line,
                          fill: '#585858',
                        },
                      }}
                    />
                  );
                })
              }
            </Geographies>
          )}
        </ZoomableGroup>
      </ComposableMap>
    )}
  </Motion>
);

interface Props extends Pick<State, 'center' | 'zoom'> {
  defaultCenter: [number, number];
  countByCountry: { [country: string]: number };
  scale: ScaleLinear<string, string>;
  onClick: (
    code: string,
    name: string
  ) => (geography: object, evt: React.MouseEvent<SVGPathElement>) => void;
  buckets: AggBucket[];
}
interface GeographyObject {
  id: string;
  properties: {
    'Alpha-2': string;
    name: string;
  };
}
