import React, { Component } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { Alert } from 'reactstrap';

import { ApiPostResponse, ProjectMetrics } from '../../services';
import { Context } from '../../state';
import { ProjectForm } from './ProjectForm';

export class ProjectCreate extends Component<RouteComponentProps, State> {
  public static contextType = Context;
  public context!: React.ContextType<typeof Context>;
  public readonly state: State = {
    status: 'UNSUBMITTED',
  };

  public render() {
    const submitForm = this.submitForm.bind(this);
    return (
      <div>
        <h2>Create new project</h2>

        {this.state.status === 'CREATE_SUCCESS' && (
          <Redirect
            to={{
              pathname: `/admin/projects/${ (this.state.response!)._id}`,
              state: { status: 'CREATE_SUCCESS' },
            }}
          />
        )}

        {this.state.status === 'CREATE_FAILED' && (
          <Alert color="danger">
            <strong>Creating project failed</strong> {this.state.error}
          </Alert>
        )}

        <ProjectForm submitForm={submitForm} />
      </div>
    );
  }

  private submitForm(data: ProjectMetrics) {
    this.context.api.projectMetrics.write(data)
      .then((response: ApiPostResponse) => {
        this.setState({
          response,
          status: 'CREATE_SUCCESS',
        });
      })
      .catch((error: Error) => {
        this.setState({
          error: typeof error === 'string' ? error : error.message,
          status: 'CREATE_FAILED',
        });
      })
      .then(() =>  window.scrollTo(0, 0));
  }
}

interface State {
  status:
    | 'UNSUBMITTED'
    | 'CREATE_SUCCESS'
    | 'CREATE_FAILED';
  error?: string;
  response?: ApiPostResponse;
}
