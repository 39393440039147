import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Modal as ReactstrapModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

export const Modal: React.SFC<Props> = ({ body, link, title = '', footer }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const open = () => setOpen(true);
  const close = () => setOpen(false);
  return (
    <>
      <Link to="" onClick={open} className="text-right">
        {link}
      </Link>
      <ReactstrapModal isOpen={isOpen} toggle={close} className="card">
        {title && <ModalHeader toggle={close}>{title}</ModalHeader>}
        <ModalBody>{body}</ModalBody>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ReactstrapModal>
    </>
  );
};
interface Props extends React.HTMLProps<HTMLDivElement> {
  title?: string;
  link: React.ReactNode;
  body: React.ReactNode;
  footer?: string;
}
