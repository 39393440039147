import React from 'react';

import { defaultCenter, State } from './WorldMap';

export const MapRoiLinks = ({
  center,
  zoom,
  onClick,
}: Pick<State, 'center' | 'zoom'> & {
  onClick: (
    view: Pick<State, 'center' | 'zoom'>,
    e: React.MouseEvent<HTMLElement>
  ) => void;
}) => (
  <ul className="nav nav-pills">
    {[
      {
        center: defaultCenter,
        name: 'Global',
        zoom: 1,
      },
      {
        center: [-90, 20],
        name: 'Central America/Carribean',
        zoom: 6,
      },
      {
        center: [-70, -25],
        name: 'South America',
        zoom: 2.25,
      },
      {
        center: [10, 1.25],
        name: 'Africa',
        zoom: 2.25,
      },
      {
        center: [100, 10],
        name: 'South Asia',
        zoom: 3,
      },
    ].map(({ name, ...view }) => {
      const handler = (e: React.MouseEvent<HTMLElement>) =>
        onClick(
          { zoom: view.zoom, center: view.center as [number, number] },
          e
        );
      const isActiveClass =
        center[0] === view.center[0] &&
        center[1] === view.center[1] &&
        zoom === view.zoom
          ? 'active'
          : '';
      return (
        <li key={name} className="nav-item">
          <a
            href="#"
            className={`nav-link py-1 px-3 rounded-0 ${isActiveClass}`}
            onClick={handler}
          >
            <small>{name}</small>
          </a>
        </li>
      );
    })}
  </ul>
);
