import { scaleLinear } from 'd3-scale';
import React from 'react';

import { AggBucket } from '../../../../services';
import { Context } from '../../../../state';
import { ProjectsMetricAggregations } from '../GlobalDashboard';
import { Popup } from './Popup';
import './WorldMap.scss';
import { MapLegend } from './WorldMapLegend';
import { MapRoiLinks } from './WorldMapLinks';
import { MapSvg } from './WorldMapSvg';

export const defaultCenter: [number, number] = [7.5, 15];

export class WorldMap extends React.Component<Props, State, typeof Context> {
  public static contextType = Context;
  public context!: React.ContextType<typeof Context>;

  public readonly state: State = {
    center: defaultCenter,
    popupOpen: false,
    zoom: 1,
  };

  public render() {
    const min = Math.min(...Object.values(this.countByCountry));
    const max = Math.max(...Object.values(this.countByCountry));
    const scale = scaleLinear<string>()
      .domain([min, max])
      .range(['#9AA6B8', '#29486E']);
    const { center, zoom, popupOpen, selectedCode, selectedName } = this.state;
    return (
      <div className="world-map" style={{ position: 'relative' }}>
        {popupOpen && selectedCode && selectedName && (
          <Popup
            countryName={selectedName}
            countryCode={selectedCode}
            handleClose={this.closeModal}
          />
        )}
        <MapSvg
          buckets={this.buckets}
          center={center}
          countByCountry={this.countByCountry}
          defaultCenter={defaultCenter}
          onClick={this.handleCountryClick}
          scale={scale}
          zoom={zoom}
        />
        {this.props.projectsData && <MapLegend scale={scale} />}
        <MapRoiLinks zoom={zoom} center={center} onClick={this.handleZoomTo} />
      </div>
    );
  }

  private handleZoomTo = (
    { zoom, center }: Pick<State, 'zoom' | 'center'>,
    e: React.MouseEvent<HTMLElement>
  ) => {
    e.preventDefault();
    this.setState({ zoom, center });
  }
  private closeModal = () => this.setState({ popupOpen: false });
  private handleCountryClick = (selectedCode: string, name: string) => () =>
    this.setState({
      popupOpen: !!this.countByCountry[selectedCode],
      selectedCode,
      selectedName: this.countByCountry[selectedCode] ? name : undefined,
    })
  private get buckets(): AggBucket[] {
    const {
      projectsData: { countries: { buckets = [] } = {} } = {},
    } = this.props;
    return buckets;
  }
  private get countByCountry(): { [country: string]: number } {
    return Object.assign(
      {},
      ...this.buckets.map(({ key, doc_count }) => ({
        [key]: doc_count,
      }))
    );
  }
}

interface Props {
  projectsData?: ProjectsMetricAggregations;
}
export interface State {
  center: [number, number];
  selectedCode?: string;
  selectedName?: string;
  zoom: number;
  popupOpen: boolean;
}
