import { WebAuth } from 'auth0-js';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import './App.scss';
import Analytics from './components/Analytics';
import { AppNav } from './components/AppNav';
import { Footer } from './components/Footer';
import { loginRequired } from './components/LoginRequired';
import { API_BASE, AUTH0_OPTIONS } from './config';
import { ApiService, AuthService, LoginCreds } from './services';
import { AppState, Context } from './state';
import {
  Admin,
  GlobalDashboard,
  LoginComplete,
  LoginStart,
  Logout,
  NotFound,
  ProjectDashboard,
} from './views';
import { date, fallback } from './views/dashboards/numbers';

export default class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const auth = new AuthService({
      auth0: new WebAuth(AUTH0_OPTIONS),
      onChange: this.loginHandler,
    });
    const api = new ApiService({ hostUrl: API_BASE });
    const setHeader = this.setHeader;

    this.state = {
      appState: { api, auth, setHeader },
      lastUpdated: undefined,
      loggedIn: false,
      title: 'Global Dashboard',
    };
  }

  public render() {
    const { logout } = this.state.appState.auth;
    return (
      // Avoid rerenders by providing state by reference, see https://reactjs.org/docs/context.html#caveats
      <Context.Provider value={this.state.appState}>
        <Router>
          <React.Fragment>
            <Analytics />
            <AppNav
              loggedIn={this.state.loggedIn}
              logout={logout}
              title={this.state.title}
              lastUpdated={this.state.lastUpdated}
            />
            <Container className="p-0">
              <Switch>
                <Route exact path="/" component={GlobalDashboard} />
                <Route exact path="/projects/:projectId" component={ProjectDashboard} />
                <Route
                  path="/admin"
                  component={loginRequired(Admin, this.state.loggedIn)}
                />
                <Route exact path="/login" component={LoginStart} />
                <Route exact path="/login/complete" component={LoginComplete} />
                <Route exact path="/logout" component={Logout} />

                <Route component={NotFound} />
              </Switch>
            </Container>
            <Footer />
          </React.Fragment>
        </Router>
      </Context.Provider>
    );
  }

  public loginHandler = (creds?: LoginCreds): void =>
    // It's critical that when login state changes, this.setState is called so that
    // a re-render is triggered
    this.setState({
      appState: {
        ...this.state.appState,
        api: new ApiService({ hostUrl: API_BASE, creds }),
      },
      loggedIn: !!creds,
    })

    private setHeader = (title: string, updatedDate?: string) => {
      let state: Pick<State, 'lastUpdated'|'title'> = {
        lastUpdated: undefined,
        title,
      };

      if (updatedDate) {
        const parsedDate = new Date(fallback(date(updatedDate)));
        const monthYear = new Date(parsedDate.getFullYear(), parsedDate.getMonth());

        const lastUpdated = monthYear.toLocaleString('en-US', {
          month: 'long',
          year: 'numeric',
        });
        state = {...state, lastUpdated};
      }
      this.setState(state);
    }
}

interface Props {}
interface State {
  appState: AppState;
  loggedIn: boolean;
  lastUpdated?: string;
  title: string;
}
