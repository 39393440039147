export const projectUseCases =  [
  {
    label: 'Women\'s Land Rights',
    value: 'woman_land_rights',
  },
  {
    label: 'Indigenous and Community Lands',
    value: 'indigenous_community_land',
  },
  {
    label: 'Land Administration/Government',
    value: 'land_admin_gov',
  },
  {
    label: 'Informal Settlements',
    value: 'informal_settlements',
  },
  {
    label: 'Agriculture and Agribusiness',
    value: 'agriculture',
  },
  {
    label: 'Customary Lands',
    value: 'customary_lands',
  },
  {
    label: 'Urban',
    value: 'urban',
  },
  {
    label: 'Conservation/Natural Resources',
    value: 'conservation',
  },
];

export const projectDataFields = [{
  key: 'numberOfWomen',
  legend: 'Number of women surveyed',
}, {
  key: 'numberOfMen',
  legend: 'Number of men surveyed',
}, {
  key: 'numberOfHouseholds',
  legend: 'Number of households surveyed',
}, {
  key: 'numberOfHectares',
  legend: 'Number of Hectares documented',
}, {
  key: 'numberOfParcels',
  legend: 'Number of Parcels documented',
}, {
  fields: [{
    id: 'male',
    label: 'Number of parcels with male ownership',
  }, {
    id: 'female',
    label: 'Number of parcels with female ownership',
  }, {
    id: 'joint',
    label: 'Number of parcels with joint ownership',
  }],
  key: 'landOwnership',
  legend: 'Land Ownership',
}, {
  fields: [{
    id: 'unknown',
    label: 'Unknown',
  }, {
    id: 'salesReceipt',
    label: 'Sales receipt',
  }, {
    id: 'customaryTitle',
    label: 'Customary title',
  }, {
    id: 'title',
    label: 'Title/Deed',
  }, {
    id: 'certificate',
    label: 'Certificate',
  }, {
    id: 'occupancyLicense',
    label: 'Occupancy license',
  }, {
    id: 'other',
    label: 'Other',
  }, {
    id: 'none',
    label: 'None',
  }],
  key: 'documentationType',
  legend: 'Type of documentation',
}, {
  fields: [{
    id: 'customary',
    label: 'Customary',
  }, {
    id: 'informalOccupancy',
    label: 'Informal Occupancy',
  }, {
    id: 'informalCustomaryRights',
    label: 'Informal Customary Rights',
  }, {
    id: 'freehold',
    label: 'Freehold',
  }, {
    id: 'leasehold',
    label: 'Leasehold',
  }, {
    id: 'stateLand',
    label: 'State Land' ,
  }], 
  key: 'landHeld',
  legend: 'How is land held?',
}, {
  fields: [{
    id: '0_14',
    label: '0 - 14',
  }, {
    id: '15_24',
    label: '15 - 24',
  }, {
    id: '25_34',
    label: '25 - 34',
  }, {
    id: '35_44',
    label: '35 - 44',
  }, {
    id: '45_54',
    label: '45 - 54',
  }, {
    id: '55_64',
    label: '55 - 64',
  }, {
    id: 'gt_65',
    label: '65 and older',
  }],
  key: 'ageDistribution',
  legend: 'What is the distribution of ages for the head of the household?',
}, {
  fields: [{
    id: 'agriculture',
    label: 'Agriculture',
  }, {
    id: 'residential',
    label: 'Residential',
  }, {
    id: 'grazing',
    label: 'Grazing',
  }, {
    id: 'Fallow',
    label: 'Fallow (unused land)',
  }, {
    id: 'commercial',
    label: 'Commercial',
  }, {
    id: 'unimproved',
    label: 'Unimproved',
  }, {
    id: 'forest',
    label: 'Forest',
  }, {
    id: 'pastoral',
    label: 'Pastoral',
  }, {
    id: 'naturalResources',
    label: 'Natural Resources',
  }, {
    id: 'fishery',
    label: 'Fishery',
  }, {
    id: 'storage',
    label: 'Storage',
  }, {
    id: 'other',
    label: 'Other',
  }, {
    id: 'industrial',
    label: 'Industrial',
  }],
  key: 'landUse',
  legend: 'Land Use',
}];
