import React, { Component } from 'react';
import { Col } from 'reactstrap';

import bbox from '@turf/bbox';
import flip from '@turf/flip';
import * as esri from 'esri-leaflet';
import { Map, Polygon } from 'react-leaflet';
import Control from 'react-leaflet-control';

import { ValidGeometry } from '../../../services/Api.interfaces';
import { LeafletPolygon } from '../../geometries';

export default class ProjectMap extends Component<Props> {
  private mapRef = React.createRef<Map>();
  private defaultBbox: Array<[number, number]>;
  private prevBbox?: L.LatLngBounds;

  public constructor(props: Props) {
    super(props);
    const [minX, minY, maxX, maxY] = bbox(props.geometry);
    this.defaultBbox = [[minY, minX], [maxY, maxX]];
  }

  public componentDidMount() {
    esri.basemapLayer('Topographic').addTo(this.mapRef.current!.leafletElement);
  }

  public render() {
    const { geometry } = this.props;
    const polygonCoordinates = flip(geometry).coordinates as LeafletPolygon;

    return (
      <Col sm="12" md="9">
        <Map
          style={{height: '400px', marginBottom: '30px'}}
          ref={this.mapRef}
          bounds={this.defaultBbox}
          onmovestart={this.setPrevBbox}
          onmoveend={this.checkBbox}
        >
          <Polygon positions={polygonCoordinates} />
          <Control position="topleft" className="leaflet-bar">
              <a
                href="#"
                title="Reset view"
                role="button"
                aria-label="Reset view"
                onClick={this.resetViewPort}
              >
                <svg className="icon icon-home" />
              </a>
          </Control>
        </Map>
      </Col>
    );
  }

  private resetViewPort = (event: React.MouseEvent) => {
    event.preventDefault();
    this.mapRef.current!.leafletElement.fitBounds(this.defaultBbox);
  }

  private setPrevBbox = () => {
    this.prevBbox = this.mapRef.current!.leafletElement.getBounds();
  }

  private checkBbox = () => {
    const map = this.mapRef.current!.leafletElement;
    const mapBounds = map.getBounds();

    if (!mapBounds.overlaps(this.defaultBbox as L.BoundsExpression)) {
      map.fitBounds(this.prevBbox ? this.prevBbox : this.defaultBbox);
    }
  }
}

interface Props {
  geometry: ValidGeometry;
}
