export const fallback = (val?: string | number) =>
  val === undefined ? '…' : val;

export const round = (val: number, fractionDigits = 0): number =>
  Math.round(val * 10 ** fractionDigits) / 10 ** fractionDigits;

export const fmtNumber = (val: number, fractionDigits = 0) =>
  round(val, fractionDigits).toLocaleString();

export const fmtPercent = (val: number, fractionDigits = 0): string =>
  `${fmtNumber(round(val * 100, fractionDigits), fractionDigits)}%`;

export const date = (
  dateStr: string,
  opts = { day: '2-digit', month: 'short', year: 'numeric' }
): string => new Date(Date.parse(dateStr)).toLocaleDateString(undefined, opts);
