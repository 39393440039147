import React, { Component } from 'react';
import { Container } from 'reactstrap';
import './Footer.scss';

export class Footer extends Component {
  public render() {
    return (
      <Container fluid className="main-navbar p-0 border-top">
        <Container>  
          <footer className="main-footer bg-white d-flex py-2">
            <span className="copyright mr-auto my-auto">Copyright © 2019</span>
            <a className="my-auto" target="_blank" href="https://cadasta.org/privacy-policy/">Privacy Policy</a>
            <a className="my-auto ml-3" target="_blank" href="https://cadasta.org/terms-of-use/">Terms of Use</a>
          </footer>
        </Container>
      </Container>
    );
  }
}
