import { getData } from 'country-list';

const overrideCountries: { [code: string]: string } = {
  CD: 'Democratic Republic of the Congo',
  TZ: 'Tanzania',
  XK: 'Kosovo',
};

export const countries: { [code: string]: string } = {
  ...Object.assign(
    {},
    ...getData().map(({ code, name }) => ({ [code]: name }))
  ),
  ...overrideCountries,
};

export const countriesArr: Array<{
  code: string;
  name: string;
}> = Object.entries(countries)
  .sort(
    ([code1, name1], [code2, name2]) =>
      name1.charCodeAt(0) - name2.charCodeAt(0)
  )
  .map(([code, name]) => ({ code, name }));

export function getCountryName(countryCode: string): string {
  return countries[countryCode] || countryCode;
}
