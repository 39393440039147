import deepEqual from 'deep-equal';
import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input'; // tslint:disable-line no-submodule-imports

import { ProjectData, ProjectMetrics, ValidGeometry } from '../../services';
import { countriesArr } from '../utils';
import { GeoJsonInput } from './GeojsonInput';
import * as ProjectFields from './ProjectFields';

export class ProjectForm extends Component<Props, State> {
  public readonly state: State = {
    data: {
      activeGovEngagement: false,
      addedBy: '',
      addedOn: '',
      contact: {
        email: '',
        organization: '',
      },
      country: '',
      dataCurrentAsOf: '',
      dateStarted: '',
      description: '',
      displayName: '',
      geometry: undefined,
      id: '',
      lastTrainingOn: '',
      name: '',
      partnerName: '',
      privacyLevel: 0,
      projectData: {
        ageDistribution: {},
        documentationType: {},
        landHeld: {},
        landOwnership: {},
        landUse: {},
        numberOfHectares: 0,
        numberOfHouseholds: 0,
        numberOfMen: 0,
        numberOfParcels: 0,
        numberOfWomen: 0,
      },
      useCases: [],
    },
  };

  public componentDidUpdate(prevProps: Props) {
    if (this.props.data && !deepEqual(this.props.data, prevProps.data)) {
      this.setState({ data: this.props.data });
    }
  }

  public render() {
    const today: string = new Date().toISOString().split('T')[0];
    const data: { [key: string]: any } = this.state.data;
    const projectData: { [key: string]: any } = this.state.data.projectData;

    const baseProjectFields = [
      {
        id: 'name',
        label: 'Project Name',
        onChange: this.onChange,
        required: true,
        type: 'text',
      },
      {
        id: 'displayName',
        label: 'Project Display Name',
        onChange: this.onChange,
        required: true,
        type: 'text',
      },
      {
        id: 'privacyLevel',
        label: 'Privacy Level',
        onChange: this.onIntChange,
        options: [
          {
            label:
              'Level 0 - project information will not be displayed publicly',
            value: '0',
          },
          {
            label:
              'Level 1 - displayed project information will be very limited',
            value: '1',
          },
          {
            label: 'Level 2 - displayed project information will be limited',
            value: '2',
          },
          {
            label: 'Level 3 - project information will be displayed in detail',
            value: '3',
          },
        ],
        required: true,
        type: 'select',
      },
      {
        id: 'description',
        label: 'Description',
        onChange: this.onChange,
        required: true,
        type: 'textarea',
      },
      {
        id: 'partnerName',
        label: 'Partner name',
        onChange: this.onChange,
        required: true,
        type: 'text',
      },
      {
        id: 'country',
        label: 'Country',
        onChange: this.onChange,
        options: [
          { value: '', label: 'Select country' },
          ...countriesArr.map(({ code: value, name: label }) => ({
            label,
            value,
          })),
        ],
        required: true,
        type: 'select',
      },
      {
        id: 'organization',
        label: 'Partner Contact Organization',
        onChange: this.onContactChange,
        type: 'text',
      },
      {
        id: 'email',
        label: 'Partner Contact Email',
        onChange: this.onContactChange,
        type: 'email',
      },
      {
        id: 'lastTrainingOn',
        label: 'Date of last training',
        max: today,
        onChange: this.onChange,
        required: true,
        type: 'date',
      },
      {
        id: 'dateStarted',
        label: 'Date of initial data collection',
        max: today,
        onChange: this.onChange,
        required: true,
        type: 'date',
      },
      {
        id: 'dataCurrentAsOf',
        label: 'Data current as of',
        max: today,
        onChange: this.onChange,
        required: true,
        type: 'date',
      },
      {
        id: 'activeGovEngagement',
        label: 'This project actively engages the government',
        onChange: this.onCheckboxChange,
        required: false,
        type: 'checkbox',
      },
      {
        id: 'useCases',
        label: 'Project Use Cases',
        multiple: true,
        onChange: this.onMultiSelectChange,
        options: ProjectFields.projectUseCases,
        required: true,
        type: 'select',
      },
    ];

    return (
      <Form onSubmit={this.onSubmit}>
        <Card className="mb-4">
          <CardHeader className="section-header">
            <h2 className="text-uppercase m-0">Project Info</h2>
          </CardHeader>
          <CardBody>
            {baseProjectFields.map(
              ({
                id,
                type,
                onChange,
                label,
                required,
                multiple,
                max,
                options,
              }) => {
                const InputElement = (
                  <Input
                    type={type as InputType}
                    name={id}
                    id={id}
                    onChange={onChange}
                    value={
                      ['organization', 'email'].includes(id)
                        ? data.contact[id]
                        : data[id]
                    }
                    max={max}
                    multiple={multiple}
                    required={required}
                    checked={
                      type === 'checkbox' &&
                      (this.state.data as { [key: string]: any })[id] === true
                    }
                  >
                    {options &&
                      options.map(
                        ({
                          value: optionValue,
                          label: optionLabel,
                        }: {
                          value: string;
                          label: string;
                        }) => (
                          <option key={optionValue} value={optionValue}>
                            {optionLabel}
                          </option>
                        )
                      )}
                  </Input>
                );
                return (
                  <FormGroup key={id} check={type === 'checkbox'}>
                    <Label for={id} check={type === 'checkbox'}>
                      {type === 'checkbox' && InputElement}
                      {label}
                    </Label>
                    {type !== 'checkbox' && InputElement}
                  </FormGroup>
                );
              }
            )}
            <GeoJsonInput
              value={data.geometry}
              onChange={this.onGeometryChange.bind(this, 'geometry')}
            />
          </CardBody>
        </Card>

        <Card>
          <CardHeader className="section-header">
            <h2 className="text-uppercase m-0">Project Data</h2>
          </CardHeader>
          <CardBody>
            {ProjectFields.projectDataFields.map(({ key, legend, fields }) =>
              fields ? (
                <FormGroup tag="fieldset" key={key}>
                  <legend>{legend}</legend>
                  {fields.map(({ id, label }) => (
                    <FormGroup row key={id}>
                      <Label for={id} sm={6}>
                        {label}
                      </Label>
                      <Col sm={4}>
                        <Input
                          type="number"
                          name={id}
                          id={id}
                          groupkey={key}
                          onChange={this.onProjectDataChange}
                          value={
                            (projectData &&
                              projectData[key] &&
                              projectData[key][id]) ||
                            0
                          }
                          min="0"
                        />
                      </Col>
                    </FormGroup>
                  ))}
                </FormGroup>
              ) : (
                <FormGroup key={key}>
                  <Label for={key}>{legend}</Label>
                  <Input
                    type="number"
                    name={key}
                    id={key}
                    onChange={this.onProjectDataChange}
                    value={(projectData && projectData[key]) || 0}
                    min="0"
                  />
                </FormGroup>
              )
            )}
          </CardBody>
        </Card>
        <Button color="primary">Submit</Button>
      </Form>
    );
  }

  private updateDataInState = (name: string, value: any) => {
    this.setState({
      data: {
        ...(this.state.data || {}),
        [name]: value,
      } as ProjectMetrics,
    });
  }

  private onContactChange = ({
    currentTarget: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    this.updateDataInState('contact', {
      ...this.state.data.contact,
      [name]: value,
    });
  }

  private onProjectDataChange = ({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const groupkey: string = currentTarget.getAttribute('groupkey')!;
    const fieldName: string = currentTarget.name;

    const data: ProjectData = this.state.data.projectData;
    if (groupkey) {
      data[groupkey] = {
        ...(data as { [key: string]: any })[groupkey],
        [fieldName]: parseInt(currentTarget.value, 10),
      };
    } else {
      data[fieldName] = parseInt(currentTarget.value, 10);
    }

    this.updateDataInState('projectData', data);
  }

  private onIntChange = ({
    currentTarget: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    this.updateDataInState(name, parseInt(value, 10));
  }

  private onGeometryChange = (name: string, geometry: ValidGeometry) => {
    this.updateDataInState(name, geometry);
  }

  private onCheckboxChange = ({
    currentTarget: { name, checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    this.updateDataInState(name, checked);
  }

  private onMultiSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedValues = [];
    const selectedOptions = (event.currentTarget as any)
      .selectedOptions as HTMLCollection;
    for (let i = 0, len = selectedOptions.length; i < len; i++) {
      const selectedItem = selectedOptions[i] as HTMLOptionElement;
      if (selectedItem) {
        selectedValues.push(selectedItem.value);
      }
    }

    this.updateDataInState(event.currentTarget.name, selectedValues);
  }

  private onChange = ({
    currentTarget: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    this.updateDataInState(name, value);
  }

  private onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props.submitForm(this.state.data as ProjectMetrics);
  }
}

interface Props {
  submitForm: (data: ProjectMetrics) => void;
  data?: ProjectMetrics;
}

interface State {
  data: Omit<ProjectMetrics, 'geometry'> &
    Partial<Pick<ProjectMetrics, 'geometry'>>;
}
