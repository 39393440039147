import React from 'react';
import { GenericChart, GenericChartProps } from './GenericChart';

const spec = {
  $schema: 'https://vega.github.io/schema/vega/v4.json',
  autosize: 'fit',
  axes: [
    { orient: 'bottom', scale: 'xscale', tickCount: 3, offset: 6 },
    { orient: 'left', scale: 'yscale' },
  ],
  data: [{ name: 'table' }],
  marks: [
    {
      encode: {
        enter: {
          fill: { field: 'color' },
          height: { scale: 'yscale', band: 1 },
          tooltip: {
            signal: 'datum.category + \': \' + datum.value',
          },
          x: { scale: 'xscale', value: 0 },
          x2: { scale: 'xscale', field: 'value', fill: { color: '#f00' } },
          y: {
            field: 'category',
            scale: 'yscale',
          },
        },
        hover: {
          fillOpacity: { value: 0.8 },
        },
        update: {
          fillOpacity: { value: 1 },
        },
      },
      from: { data: 'table' },
      type: 'rect',
    },
  ],
  padding: { left: 5, right: 5, top: 5, bottom: 5 },
  range: { category: { scheme: 'cadasta' } },
  scales: [
    {
      domain: {
        data: 'table',
        field: 'category',
      },
      name: 'yscale',
      padding: 0.05,
      range: 'height',
      round: true,
      type: 'band',
    },
    {
      domain: { data: 'table', field: 'value' },
      name: 'xscale',
      nice: true,
      range: 'width',
    },
  ],
};

export default (props: GenericChartProps) => (
  <GenericChart spec={spec} {...props} />
);
