import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from 'reactstrap';
import { Context } from '../state';
import './AppNav.scss';
import logo from './logo.png';

export class AppNav extends Component<Props, State> {
  public static contextType = Context;
  public readonly state: State = { isOpen: false };
  public context!: React.ContextType<typeof Context>;

  public render() {
    return (
      <Container fluid className="main-navbar p-0 mb-0">
        <Container>
          <Navbar light color="faded" expand="md" className="px-0">
            <NavbarBrand href="https://cadasta.org">
              <img src={logo} alt="Cadasta" className="logo mr-1" />
            </NavbarBrand>
            <div className="ml-auto">
              <h1 className="page-title p-0 m-0">
                {this.props.title}
                {this.props.lastUpdated &&
                  <span className="updated text-right m-0">
                    Last updated {this.props.lastUpdated}
                  </span>
                }
              </h1>
            </div>
          </Navbar>
        </Container>
        {this.props.loggedIn && (
          <Container fluid className="border-top">
            <Container>
              <Navbar light color="faded" expand="md" className="p-0 admin-nav">
                <NavbarToggler onClick={this.toggleOpen} className="my-2" />
                <Collapse isOpen={this.state.isOpen} navbar>
                  <Nav className="ml-auto" navbar>
                    {Object.entries({
                      '/admin': 'Edit Metrics',
                      '/logout': 'Logout',
                    }).map(([url, name]) => (
                      <NavItem key={url}>
                        <NavLink tag={Link} onClick={this.close} to={url}>
                          {name}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </Collapse>
              </Navbar>
            </Container>
          </Container>
        )}
      </Container>
    );
  }
  
  private toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
  private close = () => this.setState({ isOpen: false });
}
interface Props {
  loggedIn: boolean;
  logout: () => void;
  title: string;
  lastUpdated?: string;
}
interface State {
  isOpen: boolean;
}
