import React from 'react';
import { Link } from 'react-router-dom';
import { PaginationItem, PaginationLink } from 'reactstrap';

export const PaginationLinks = ({
  currentPage,
  total,
  pageSize,
  currentSearch = '',
  className = '',
  hideSinglePage = false,
  onClick,
  ...props
}: Props) => {
  const maxPage = Math.ceil(total / pageSize);
  const currentSearchObj = parseQuerystring(currentSearch);
  const buildQuerystring = querystringBuilder(currentSearchObj);

  const prevPage = Math.max(currentPage - 1, 1);
  const prev = onClick
    ? { onClick: () => onClick(prevPage) }
    : {
        tag: Link,
        to: { search: buildQuerystring(prevPage) },
      };
  const nextPage = Math.min(currentPage + 1, maxPage);
  const next = onClick
    ? { onClick: () => onClick(nextPage) }
    : {
        tag: Link,
        to: { search: buildQuerystring(nextPage) },
      };
  return (
    <ul
      className={`pagination ${className} ${hideSinglePage &&
        maxPage === 1 &&
        'd-none'}`}
      {...props}
    >
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink {...prev as any} previous />
      </PaginationItem>
      <PaginationItem disabled>
        <PaginationLink>
          {currentPage} of {maxPage}
        </PaginationLink>
      </PaginationItem>
      <PaginationItem disabled={currentPage === maxPage}>
        <PaginationLink {...next as any} next />
      </PaginationItem>
    </ul>
  );
};

interface Props
  extends Omit<React.HTMLAttributes<HTMLUListElement>, 'onClick'> {
  total: number;
  pageSize: number;
  currentPage: number;
  currentSearch?: string;
  hideSinglePage?: boolean;
  onClick?: (page: number) => void;
}

export function parseQuerystring(queryString: string) {
  const pairs = (queryString[0] === '?'
    ? queryString.substr(1)
    : queryString
  ).split('&');
  return Object.assign(
    {},
    ...pairs
      .map((pair) => pair.split('='))
      .filter((kv) => kv.length === 2)
      .map(([key, value = '']) => ({
        [key]: value,
      }))
  );
}

function querystringBuilder(currentQuery: { [k: string]: string }) {
  return (page: number) =>
    `${Object.entries({
      ...currentQuery,
      page,
    })
      .map(([k, v]) => `${k}=${v}`)
      .join('&')}`;
}
