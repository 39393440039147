import { AuthOptions } from 'auth0-js';

const prodMode = process.env.NODE_ENV === 'production';

const appHost = env('REACT_APP_DOMAIN', !prodMode && 'http://localhost:3000');
const apiHost = env('REACT_APP_API_HOST', !prodMode && 'http://localhost:8080');

export const API_BASE = `${apiHost}/dashboard/v1`;

export const AUTH0_OPTIONS: AuthOptions = {
  audience: env('REACT_APP_API_AUDIENCE', 'dashboard.api'),
  clientID: '99xpD6fj9d3VUiQTm9b8nBUMi5FEtIWS',
  domain: 'cadasta.auth0.com',
  redirectUri: `${appHost}/login/complete`,
  responseType: 'token id_token',
  scope: 'openid email',
};

function env(name: string, fallback?: string | false): string {
  const value = process.env[name] || fallback;
  if (value) {
    return value;
  }
  throw new Error(`Missing environment variable: ${name}`);
}
