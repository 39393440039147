import React, { Component } from 'react';
import { Table } from 'reactstrap';

import { createClassFromSpec } from 'react-vega';
import { Handler } from 'vega-tooltip';

import { Spinner } from '../../../components/Spinner';
import {
  ChartInfo,
  InnerCardBody,
  StatsBox,
  StatsBoxHeader,
  StatsBoxValue,
} from '../layout';
import { fallback, fmtNumber, fmtPercent } from '../numbers';
import { GetParentDimensions } from './GetParentDimensions';
import { ColoredDataItem, DataItem } from './interfaces';
import { prepChartData } from './utils';

const defaultHeight = 200;

export class GenericChart extends Component<GenericChartProps & ChartSpec> {
  public render() {
    if (this.props.data === undefined) {
      return (
        <InnerCardBody>
          <StatsBox>
            <StatsBoxHeader>{this.props.label}</StatsBoxHeader>
            {this.props.showTotal && <StatsBoxValue>Loading...</StatsBoxValue>}
          </StatsBox>
          <MissingData>
            <Spinner />
          </MissingData>
        </InnerCardBody>
      );
    }
    const data: ColoredDataItem[] = prepChartData(
      this.props.data,
      this.props.maxFields,
      this.props.sorted,
      this.props.colors
    );
    const total = data.reduce((sum, { value }) => sum + value, 0);
    return (
      <InnerCardBody>
        <StatsBox>
          <StatsBoxHeader>{this.props.label}</StatsBoxHeader>
          {this.props.showTotal && total > 0 && (
            <StatsBoxValue>{fallback(total && fmtNumber(total))}</StatsBoxValue>
          )}
        </StatsBox>
        {total > 0 ? (
          <>
            <div className="chart text-center mb-4">
              {!!data.length && (
                // Render chart at full width of its container
                <GetParentDimensions
                  style={{
                    height: this.props.height || defaultHeight,
                    width: '100%',
                  }}
                >
                  {({ width, height }) => {
                    const Chart = createClassFromSpec('Chart', {
                      ...this.props.spec,
                      height,
                      width,
                    });
                    return (
                      <Chart
                        data={{ table: data }}
                        tooltip={new Handler().call}
                        width={
                          this.props.isPie ? Math.min(width, height) : width
                        }
                        height={
                          this.props.isPie ? Math.min(width, height) : height
                        }
                      />
                    );
                  }}
                </GetParentDimensions>
              )}
            </div>
            <Table className="text-small mb-0">
              <tbody>
                {data.map(({ category, color, value }, i) => (
                  <tr key={i}>
                    <td>
                      {!this.props.legendColorsOff && (
                        <div
                          className="square"
                          style={{ backgroundColor: color }}
                        />
                      )}
                      {category}
                    </td>
                    {this.props.showNumbers && (
                      <td className="text-right">{fmtNumber(value)}</td>
                    )}
                    {!this.props.percentsOff && (
                      <td className="text-right percentCol">
                        {fmtPercent(value / total || 0, 1)}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            {this.props.info && <ChartInfo>{this.props.info}</ChartInfo>}
          </>
        ) : (
          <MissingData>Chart data not available</MissingData>
        )}
      </InnerCardBody>
    );
  }
}

interface ChartSpec {
  spec: {
    [key: string]: any;
  };
}

function MissingData({ children }: { children: React.ReactNode }) {
  return (
    <div className="jumbotron mb-0">
      <p className="text-center small chart-msg mb-0">{children}</p>
    </div>
  );
}

export interface GenericChartProps {
  label: string;
  data?: DataItem[];
  showTotal?: boolean;
  showNumbers?: boolean;
  percentsOff?: boolean;
  maxFields?: number;
  height?: number;
  isPie?: boolean;
  sorted?: boolean;
  colors?: string[];
  info?: string;
  legendColorsOff?: boolean;
}
