import React, { CSSProperties } from 'react';

export class GetParentDimensions extends React.Component<Props, State> {
  public readonly state: State = {
    height: 0,
    width: 0,
  };
  public ref: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.ref = React.createRef();
  }

  public componentDidMount() {
    this.setSize();
    window.addEventListener('resize', this.setSize);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.setSize);
  }

  public setSize = () =>
    this.setState({
      height: this.ref.current.offsetHeight,
      width: this.ref.current.offsetWidth,
    })

  public render() {
    return (
      <div ref={this.ref} style={this.props.style}>
        {this.props.children(this.state)}
      </div>
    );
  }
}
interface Props {
  style: CSSProperties;
  children: (state: State) => any;
}
interface State {
  width: number;
  height: number;
}
