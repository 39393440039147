import React from 'react';
import { GenericChart, GenericChartProps } from './GenericChart';

const spec = {
  $schema: 'https://vega.github.io/schema/vega/v4.json',
  autosize: 'fit',
  axes: [{ orient: 'left', scale: 'yscale', tickCount: 5, offset: 6 }],
  data: [{ name: 'table' }],
  marks: [
    {
      encode: {
        enter: {
          fill: { field: 'color' },
          tooltip: {
            signal: 'datum.category + \': \' + datum.value',
          },
          width: { scale: 'xscale', band: 1 },
          x: {
            field: 'category',
            scale: 'xscale',
          },
          y: { scale: 'yscale', field: 'value', fill: { color: '#f00' } },
          y2: { scale: 'yscale', value: 0 },
        },
        hover: {
          fillOpacity: { value: 0.8 },
        },
        update: {
          fillOpacity: { value: 1 },
        },
      },
      from: { data: 'table' },
      type: 'rect',
    },
  ],
  padding: { left: 5, right: 5, top: 5, bottom: 5 },
  range: { category: { scheme: 'cadasta' } },
  scales: [
    {
      domain: {
        data: 'table',
        field: 'category',
      },
      name: 'xscale',
      padding: 0.05,
      range: 'width',
      round: true,
      type: 'band',
    },
    {
      domain: { data: 'table', field: 'value' },
      name: 'yscale',
      nice: true,
      range: 'height',
    },
  ],
};

export default (props: GenericChartProps) => (
  <GenericChart spec={spec} {...props} />
);
