import React, { Component } from 'react';

import { GlobalMetrics } from '../../../services';
import { Context } from '../../../state';
import { QuickStats } from './QuickStats';
import { WhereStats } from './WhereStats';
import { WhoStats } from './WhoStats';

export class GlobalDashboard extends Component<{}, State> {
  public static contextType = Context;
  public readonly state: State = {};
  public context!: React.ContextType<typeof Context>;
  private readonly title = 'Global Dashboard';

  public componentDidMount() {
    this.context.setHeader(this.title);
    if (!this.state.globalStatus) {
      this.fetchGlobal();
    }
    if (!this.state.projectsStatus) {
      this.fetchProjects();
    }
    if (!this.state.numberOfUsersStatus) {
      this.fetchNumberOfUsers();
    }
  }

  public render() {
    const { globalData, projectsData, numberOfUsersData } = this.state;

    return (
      <>
        <QuickStats globalData={globalData} projectsData={projectsData} numberOfUsersData={numberOfUsersData} />
        <WhereStats globalData={globalData} projectsData={projectsData} />
        <WhoStats globalData={globalData} projectsData={projectsData} />
      </>
    );
  }

  private fetchGlobal = async () => {
    this.setState({ globalStatus: 'loading' });
    try {
      const globalData = await this.context.api.globalMetrics.latest();
      this.setState({
        globalData,
        globalError: undefined,
        globalStatus: 'loaded',
      });
      this.context.setHeader(this.title, globalData.addedOn);
      console.log({ globalData });
    } catch (globalError) {
      this.setState({
        globalData: undefined,
        globalError,
        globalStatus: 'failed',
      });
    }
  }

  private fetchProjects = async () => {
    this.setState({ projectsStatus: 'loading' });
    const projectsQuery = {
      'activeGovEngagement': {
        agg: 'terms',
        field: 'activeGovEngagement',
      },
      'ageDistribution.0_14': {
        agg: 'sum',
        field: 'projectData.ageDistribution.0_14',
      },
      'ageDistribution.15_24': {
        agg: 'sum',
        field: 'projectData.ageDistribution.15_24',
      },
      'ageDistribution.25_34': {
        agg: 'sum',
        field: 'projectData.ageDistribution.25_34',
      },
      'ageDistribution.35_44': {
        agg: 'sum',
        field: 'projectData.ageDistribution.35_44',
      },
      'ageDistribution.45_54': {
        agg: 'sum',
        field: 'projectData.ageDistribution.45_54',
      },
      'ageDistribution.55_64': {
        agg: 'sum',
        field: 'projectData.ageDistribution.55_64',
      },
      'ageDistribution.gt_65': {
        agg: 'sum',
        field: 'projectData.ageDistribution.gt_65',
      },
      'countries': {
        agg: 'terms',
        field: 'country.keyword',
        opts: {
          size: 1000,
        },
      },
      'documentationType.certificate': {
        agg: 'sum',
        field: 'projectData.documentationType.certificate',
      },
      'documentationType.customaryTitle': {
        agg: 'sum',
        field: 'projectData.documentationType.customaryTitle',
      },
      'documentationType.none': {
        agg: 'sum',
        field: 'projectData.documentationType.none',
      },
      'documentationType.occupancyLicense': {
        agg: 'sum',
        field: 'projectData.documentationType.occupancyLicense',
      },
      'documentationType.other': {
        agg: 'sum',
        field: 'projectData.documentationType.other',
      },
      'documentationType.salesReceipt': {
        agg: 'sum',
        field: 'projectData.documentationType.salesReceipt',
      },
      'documentationType.title': {
        agg: 'sum',
        field: 'projectData.documentationType.title',
      },
      'documentationType.unknown': {
        agg: 'sum',
        field: 'projectData.documentationType.unknown',
        
      },
      'landHeld.customary': {
        agg: 'sum',
        field: 'projectData.landHeld.customary',
      },
      'landHeld.freehold': {
        agg: 'sum',
        field: 'projectData.landHeld.freehold',
      },
      'landHeld.informalCustomaryRights': {
        agg: 'sum',
        field: 'projectData.landHeld.informalCustomaryRights',
      },
      'landHeld.informalOccupancy': {
        agg: 'sum',
        field: 'projectData.landHeld.informalOccupancy',
      },
      'landHeld.leasehold': {
        agg: 'sum',
        field: 'projectData.landHeld.leasehold',
      },
      'landHeld.stateLand': {
        agg: 'sum',
        field: 'projectData.landHeld.stateLand',
      },
      'landUse.Fallow': {
        agg: 'sum',
        field: 'projectData.landUse.Fallow',
      },
      'landUse.agriculture': {
        agg: 'sum',
        field: 'projectData.landUse.agriculture',
      },
      'landUse.commercial': {
        agg: 'sum',
        field: 'projectData.landUse.commercial',
      },
      'landUse.fishery': {
        agg: 'sum',
        field: 'projectData.landUse.fishery',
      },
      'landUse.forest': {
        agg: 'sum',
        field: 'projectData.landUse.forest',
      },
      'landUse.grazing': {
        agg: 'sum',
        field: 'projectData.landUse.grazing',
      },
      'landUse.industrial': {
        agg: 'sum',
        field: 'projectData.landUse.industrial',
      },
      'landUse.naturalResources': {
        agg: 'sum',
        field: 'projectData.landUse.naturalResources',
      },
      'landUse.other': {
        agg: 'sum',
        field: 'projectData.landUse.other',
      },
      'landUse.pastoral': {
        agg: 'sum',
        field: 'projectData.landUse.pastoral',
      },
      'landUse.residential': {
        agg: 'sum',
        field: 'projectData.landUse.residential',
      },
      'landUse.storage': {
        agg: 'sum',
        field: 'projectData.landUse.storage',
      },
      'landUse.unimproved': {
        agg: 'sum',
        field: 'projectData.landUse.unimproved',
      },
      'numHectares': {
        agg: 'sum',
        field: 'projectData.numberOfHectares',
      },
      'numHouseholds': {
        agg: 'sum',
        field: 'projectData.numberOfHouseholds',
      },
      'numMen': {
        agg: 'sum',
        field: 'projectData.numberOfMen',
      },
      'numParcels': {
        agg: 'sum',
        field: 'projectData.numberOfParcels',
      },
      'numProjects': {
        agg: 'value_count',
        field: 'name.keyword',
      },
      'numWomen': {
        agg: 'sum',
        field: 'projectData.numberOfWomen',
      },
    };
    try {
      const projectsData = await this.context.api.projectMetrics.aggs(
        projectsQuery
      );
      this.setState({
        projectsData,
        projectsError: undefined,
        projectsStatus: 'loaded',
      });
      console.log({ projectsData });
    } catch (projectsError) {
      this.setState({
        projectsData: undefined,
        projectsError,
        projectsStatus: 'failed',
      });
    }
  }

  private fetchNumberOfUsers = async () => {
    this.setState({ numberOfUsersStatus: 'loading' });

    try {
      const numberOfUsersData = await this.context.api.globalMetrics.dateAggreation(
        'numberUsers',
        'addedOn',
        'quarter'
      );
      this.setState({
        numberOfUsersData,
        numberOfUsersError: undefined,
        numberOfUsersStatus: 'loaded',
      });
      console.log(numberOfUsersData);
    } catch (numberOfUsersError) {
      this.setState({
        numberOfUsersData: undefined,
        numberOfUsersError,
        numberOfUsersStatus: 'failed',
      });
    }
  }
}

interface State {
  globalStatus?: 'loading' | 'loaded' | 'failed';
  globalData?: GlobalMetrics;
  globalError?: Error;
  projectsStatus?: 'loading' | 'loaded' | 'failed';
  projectsData?: ProjectsMetricAggregations;
  projectsError?: Error;
  numberOfUsersStatus?: 'loading' | 'loaded' | 'failed';
  numberOfUsersData?: NumberOfUsersAggregation;
  numberOfUsersError?: Error;
}

export type NumberOfUsersAggregation = Array<{
  key_as_string: string,
  key: number,
  doc_count: number,
  value: number,
}>;

export interface ProjectsMetricAggregations {
  activeGovEngagement: AggBucketResponse;
  countries: AggBucketResponse;
  numProjects: AggMetricResponse;
  numParcels: AggMetricResponse;
  numHectares: AggMetricResponse;
  numWomen: AggMetricResponse;
  numMen: AggMetricResponse;
  numHouseholds: AggMetricResponse;
  'landHeld.customary': AggMetricResponse;
  'landHeld.freehold': AggMetricResponse;
  'landHeld.informalCustomaryRights': AggMetricResponse;
  'landHeld.informalOccupancy': AggMetricResponse;
  'landHeld.leasehold': AggMetricResponse;
  'landHeld.stateLand': AggMetricResponse;
  'landUse.Fallow': AggMetricResponse;
  'landUse.agriculture': AggMetricResponse;
  'landUse.commercial': AggMetricResponse;
  'landUse.fishery': AggMetricResponse;
  'landUse.forest': AggMetricResponse;
  'landUse.grazing': AggMetricResponse;
  'landUse.industrial': AggMetricResponse;
  'landUse.naturalResources': AggMetricResponse;
  'landUse.other': AggMetricResponse;
  'landUse.pastoral': AggMetricResponse;
  'landUse.residential': AggMetricResponse;
  'landUse.storage': AggMetricResponse;
  'landUse.unimproved': AggMetricResponse;
  'documentationType.unknown': AggMetricResponse;
  'documentationType.salesReceipt': AggMetricResponse;
  'documentationType.customaryTitle': AggMetricResponse;
  'documentationType.title': AggMetricResponse;
  'documentationType.certificate': AggMetricResponse;
  'documentationType.occupancyLicense': AggMetricResponse;
  'documentationType.other': AggMetricResponse;
  'documentationType.none': AggMetricResponse;
  'ageDistribution.0_14': AggMetricResponse;
  'ageDistribution.15_24': AggMetricResponse;
  'ageDistribution.25_34': AggMetricResponse;
  'ageDistribution.35_44': AggMetricResponse;
  'ageDistribution.45_54': AggMetricResponse;
  'ageDistribution.55_64': AggMetricResponse;
  'ageDistribution.gt_65': AggMetricResponse;
}
interface AggBucketResponse {
  buckets: Array<{
    key: string | number;
    doc_count: number;
  }>;
}
interface AggMetricResponse {
  value: number;
}
