import bbox from '@turf/bbox';
import cleanCoords from '@turf/clean-coords';
import flip from '@turf/flip';
import GeojsonValidator from 'geojson-validation';
import 'leaflet';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Map, Polygon, TileLayer } from 'react-leaflet';
import { Col, Input, Row } from 'reactstrap';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { ValidGeometry } from '../../services';
import { LeafletPolygon } from '../geometries';

export const GeoJsonInput = ({ value, onChange }: Props) => {
  const geomInput = React.createRef<HTMLInputElement>();
  const [rawInput, setRawInput] = useState<string>(''); // On mount, `value` is likely `undefined`
  
  const [isValid, setIsValid] = useState<boolean>(
    GeojsonValidator.valid(value)
  );
  const [err, setErr] = useState<string[] | undefined>(undefined);
  const testValidation = (e: string[]) => {
    setIsValid(!e.length);
    setErr(e);
    if (geomInput.current) {
      geomInput.current.setCustomValidity('Provide valid GeoJSON.');
    }
  };

  function setGeom() {
    try {
      const geom: ValidGeometry = JSON.parse(rawInput);
      GeojsonValidator.valid(geom, (_: boolean, errs: string[]) => {
        testValidation(errs);
        if (!errs.length) {
             // Data is valid...
          // Send valid geometry to parent form
          onChange(geom);
          if (geomInput.current) {
            // Clear validation check
            geomInput.current.setCustomValidity('');
          }
        }
      });
    } catch (err) {
      testValidation([(err as Error).message]);
    }
  }

  function setValue() {
    setRawInput(JSON.stringify(value, null, 2));
  }

  useEffect(setValue, [JSON.stringify(value)]);
  useEffect(setGeom, [rawInput]);

  const onTextChange = ({
    currentTarget: { value: v },
  }: ChangeEvent<HTMLInputElement>) => {
    if (v) {
      let inputVal = JSON.parse(v);
      if (inputVal.type === 'FeatureCollection') {
        // In ElasticSearch, we can only store geometries. Since we need
        // to allow FeatureCollections with one feature, we're extracting 
        // the geometry of the first feature and use the geometry going forward.
        inputVal = inputVal.features[0].geometry;
      }
      setRawInput(
        JSON.stringify(
          // Some geometries exported from ArcGIS Pro contain duplicate coordinatesm
          // which ElasticSearch rejects. cleanCoords removes the duplicates.
          cleanCoords(inputVal)
        )
      );
    } else {
      setRawInput(v);
    }
  };
  const [minX, minY, maxX, maxY] = value ? bbox(value) : [0, 0, 0, 0];

  return (
    <Row noGutters style={{ minHeight: 400 }}>
      <Col sm={6}>
        <Input
          tag="textarea"
          style={{
            height: '100%',
            width: '100%',
          }}
          onChange={onTextChange}
          value={rawInput}
          required
          minLength={2}
          spellCheck={false}
          isvalid={isValid.toString()}
          innerRef={geomInput}
        />
      </Col>
      <Col sm={6}>
        {isValid && value ? (
          // Just in case map fails
          <ErrorBoundary>
            <Map
              center={[51.505, -0.09]}
              zoom={13}
              style={{ height: '100%' }}
              bounds={[[minY, minX], [maxY, maxX]]}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Polygon positions={flip(value).coordinates as LeafletPolygon}/>
            </Map>
          </ErrorBoundary>
        ) : (
          <div className="p-2">
            {err && (
              <ol className="pl-3">
                {err!.map((e, i) => (
                  <li key={i}>{e}</li>
                ))}
              </ol>
            )}
          </div>
        )}
      </Col>
    </Row>
  );
};
interface Props {
  value?: ValidGeometry;
  onChange: (data: ValidGeometry) => void;
}
