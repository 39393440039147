import { projectDataFields } from '../../Admin/ProjectFields';
import { ProjectsMetricAggregations } from '../global/GlobalDashboard';

export const convertToVegaFormat = (projects: ProjectsMetricAggregations | undefined, fieldKey: string) => {
  if (!projects) {
    return [];
  }
  return projectDataFields
    .find(({key}) => key === fieldKey)!
    .fields!.map(({label, id}) => ({
      category: label,
      value: (projects as {[key: string]: any})[`${fieldKey}.${id}`].value || 0,
    }));
};
