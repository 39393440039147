import React from 'react';
import { Col, Row } from 'reactstrap';

import { GlobalMetrics } from '../../../services';
import BarChart from '../charts/BarChart';
import { convertToVegaFormat } from '../charts/converters';

import HorizontalBarChart from '../charts/HorizontalBarChart';
import PieChart from '../charts/PieChart';
import {
  Card,
  CardBody,
  CardGroup,
  CardHeader,
  CardHeaderSection,
  CardHeaderStep,
  InnerCard,
} from '../layout';
import { ProjectsMetricAggregations } from './GlobalDashboard';

export const WhoStats: React.SFC<Props> = ({ projectsData: projects }) => (
  <Row id="status">
    <Col sm="12" className="my-4">
      <Card>
        <CardHeaderSection>
          <CardHeader>
            <CardHeaderStep>03</CardHeaderStep>
            Land Status
          </CardHeader>
          Land Status and Use of Documented Parcels and Households
        </CardHeaderSection>
        <CardBody>
          <Row>
            <Col md="12">
              <CardGroup>
                <InnerCard className="pt-2">
                  <BarChart
                    sorted
                    label="How is the land being held?"
                    data={
                      projects === undefined
                        ? undefined
                        : convertToVegaFormat(projects, 'landHeld')
                    }
                  />
                </InnerCard>
                <InnerCard className="pt-2">
                  <BarChart
                    sorted
                    label="How is the land being used?"
                    data={
                      projects === undefined
                        ? undefined
                        : convertToVegaFormat(projects, 'landUse')
                    }
                    maxFields={6}
                  />
                </InnerCard>
              </CardGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <CardGroup>
                <InnerCard>
                  <PieChart
                    sorted
                    label="Pre-Cadasta Land Documentation Status"
                    data={
                      projects === undefined
                        ? undefined
                        : convertToVegaFormat(projects, 'documentationType')
                    }
                    maxFields={7}
                  />
                </InnerCard>
                <InnerCard>
                  <HorizontalBarChart
                    data={
                      projects === undefined
                        ? undefined
                        : convertToVegaFormat(projects, 'ageDistribution')
                    }
                    label="Head of household ages"
                  />
                </InnerCard>
              </CardGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

interface Props {
  globalData?: GlobalMetrics;
  projectsData?: ProjectsMetricAggregations;
}
