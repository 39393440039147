import React from 'react';
import ReactDOM from 'react-dom';
import { FaMapMarkerAlt } from 'react-icons/fa'; // tslint:disable-line:no-submodule-imports
import { Card, CardBody, CardHeader, CardHeaderSection } from '../../layout';

import { Link } from 'react-router-dom';
import { PaginationLinks } from '../../../../components/PaginationLinks';
import { Spinner } from '../../../../components/Spinner';
import { ParsedApiManyResponse, ProjectMetrics } from '../../../../services';
import { Context } from '../../../../state';
import './Popup.scss';

export class PopupBody extends React.Component<Props, State, typeof Context> {
  public static contextType = Context;
  public context!: React.ContextType<typeof Context>;

  public readonly state: State = { page: 1 };

  private perPage = 10;

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    this.fetch();
  }
  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  public componentDidUpdate(_: Props, { page: prevPage }: State) {
    if (prevPage !== this.state.page) {
      this.fetch();
    }
  }

  public render() {
    const { countryName, handleClose } = this.props;
    const { data } = this.state;
    return (
      <div
        id="world-map"
        style={{
          margin: '30px auto',
          maxHeight: '80%',
          width: '80%',
          zIndex: 11,
        }}
      >
        <Card className="rounded-0">
          <CardHeaderSection className="py-2 px-3">
            <a className="close" onClick={handleClose}>
              &times;
            </a>
            <CardHeader tag="h3">{countryName}</CardHeader>
          </CardHeaderSection>

          <CardBody className="p-3">
            {data ? (
              <>
                <ul className="list-unstyled">
                  {data.hits.length ? (
                    data.hits
                      .slice()
                      .sort(({ displayName: d1 }, { displayName: d2 }) =>
                        orderAlphabeticalUndefinedLast(d1, d2)
                      )
                      .map(({ displayName = 'Undisclosed Project', id }) => (
                        <li key={id} className="mb-2">
                          <FaMapMarkerAlt
                            color="darkgray"
                            size="15"
                            className="mr-2"
                            style={{ verticalAlign: 'middle' }}
                          />
                          <Link to={`/projects/${id}`}>
                            <strong style={{ color: '#2a5db0' }}>
                              {displayName}
                            </strong>
                          </Link>
                        </li>
                      ))
                  ) : (
                    <li>No public projects available.</li>
                  )}
                </ul>
                <p className="text-muted mb-0" style={{ lineHeight: '1em' }}>
                  <em>
                    <small>
                      For reasons of privacy, some projects may have been
                      omitted from this list.
                    </small>
                  </em>
                </p>
                {!!data.total && data.total > this.perPage && (
                  <PaginationLinks
                    currentPage={this.state.page}
                    total={data.total}
                    pageSize={this.perPage}
                    onClick={this.setPage}
                  />
                )}
              </>
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
  private handleClick: EventListener = ({ target }) => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(target as Node)) {
      this.props.handleClose();
    }
  }
  private fetch = () =>
    this.context.api.projectMetrics
      .list(
        {
          country: this.props.countryCode,
          fields: ['displayName', 'id'],
          page: this.state.page,
          size: this.perPage,
        },
        {
          headers: {
            // Clear auth headers to request same content as non-authenticated user
            'Authorization': '',
            'x-identity': '',
          },
        }
      )
      .then((data) => this.setState({ data }))
  private setPage = (page: number) => this.setState({ page });
}

export const Popup = (props: Props) => (
  <div
    style={{
      backgroundColor: 'rgba(0,0,0,.5)',
      height: '100%',
      position: 'absolute',
      width: '100%',
      zIndex: 10,
    }}
  >
    <PopupBody {...props} />
  </div>
);

interface Props {
  countryName: string;
  countryCode: string;
  handleClose: () => void;
}
interface State {
  data?: ParsedApiManyResponse<ProjectMetrics>;
  page: number;
}

function orderAlphabeticalUndefinedLast(
  a: string | undefined,
  b: string | undefined
): number {
  return a || b ? (!a ? 1 : !b ? -1 : a.localeCompare(b)) : 0;
}
