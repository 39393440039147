import {
  AggInput,
  ApiGetResponse,
  ApiManyResponse,
  ApiPostResponse,
  ApiServiceOptions,
  DbAddedFields,
  FetchClient,
  GlobalMetrics,
  ParsedApiManyResponse,
  ProjectMetrics,
} from './Api.interfaces';
import { LoginCreds } from './Auth';

export class ApiService {
  private hostUrl: string;
  private client: FetchClient;
  private creds?: LoginCreds;

  constructor({ hostUrl, client = fetch, creds }: ApiServiceOptions) {
    this.hostUrl = hostUrl;
    this.client = client.bind(window);
    this.creds = creds;
  }

  get globalMetrics() {
    return {
      latest: (): Promise<GlobalMetrics> =>
        this.fetch(`${this.hostUrl}/metrics/global`),

      write: (
        body: Omit<GlobalMetrics, keyof DbAddedFields>
      ): Promise<GlobalMetrics> =>
        this.fetch(`${this.hostUrl}/admin/global`, {
          body: JSON.stringify(body),
          method: 'POST',
        }),

      dateAggreation: (
        valueField: string,
        dateField: string,
        interval: string
      ): Promise<any> =>
        this.fetch(`${this.hostUrl}/metrics/global/_dateagg`, {
          body: JSON.stringify({
            dateField,
            interval,
            valueField,
          }),
          method: 'POST',
        }),
    };
  }

  get projectMetrics() {
    return {
      get: (projectId: string): Promise<ProjectMetrics> =>
        this.fetch<ApiGetResponse<ProjectMetrics>>(
          `${this.hostUrl}/metrics/projects/${projectId}`
        ).then(({ _id: id, _source }) => ({ ..._source, id })),

      list: (
        {
          fields,
          size = 10,
          page = 1,
          ...optional
        }: {
          fields: Array<keyof ProjectMetrics>;
          search?: string;
          country?: string;
          size?: number;
          page?: number;
        },
        requestOptions?: RequestInit
      ): Promise<ParsedApiManyResponse<ProjectMetrics>> => {
        const fieldStr = fields.join(',');
        const queryParams = Object.entries({
          fields: fieldStr,
          page,
          size,
          ...optional,
        })
          .map(([k, v]) => `${k}=${v}`)
          .join('&');
        return this.fetch<ApiManyResponse<Omit<ProjectMetrics, 'id'>>>(
          `${this.hostUrl}/metrics/projects?${queryParams}`,
          requestOptions
        ).then(({ hits, ...other }) => ({
          ...other,
          hits: hits.map(({ _source, _id: id }) => ({ ..._source, id })),
        }));
      },
      write: (body: Omit<ProjectMetrics, keyof DbAddedFields>) =>
        this.fetch<ApiPostResponse>(`${this.hostUrl}/admin/projects`, {
          body: JSON.stringify(body),
          method: 'POST',
        }),

      update: (id: string, body: Omit<ProjectMetrics, keyof DbAddedFields>) =>
        this.fetch<ApiPostResponse>(`${this.hostUrl}/admin/projects/${id}`, {
          body: JSON.stringify(body),
          method: 'PUT',
        }),

      aggs: <T = AggInput>(
        aggs: T
      ): Promise<{ [key in keyof T]: any }> => // TODO: specify that output can either be "buckets" or "value"
        this.fetch(`${this.hostUrl}/metrics/projects/_agg`, {
          body: JSON.stringify(aggs),
          method: 'POST',
        }),
    };
  }

  // Helper to apply headers and output checks to any HTTP requests
  private fetch<T>(input: RequestInfo, init: RequestInit = {}): Promise<T> {
    return this.client(input, {
      ...init,
      headers: {
        ...(this.creds
          ? {
              'Authorization': `Bearer ${this.creds.accessToken}`,
              'x-identity': this.creds.idToken,
            }
          : {}),
        ...(init.headers || {}), // Must be after auth headers, allowing auth to be overridden
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then((r) => r.json());
  }
}

function checkStatus(r: Response) {
  return r.status >= 200 && r.status < 300
    ? Promise.resolve(r)
    : r
        .json()
        .then(
          ({ error: { description } }): any =>
            Promise.reject(new Error(description))
        );
}
