import { WebAuth } from 'auth0-js';

export class AuthService {
  public readonly auth0: WebAuth;
  public credentials: Promise<LoginCreds>;
  private readonly onChange: ChangeHandler;

  constructor({ auth0, onChange }: AuthOptions) {
    this.auth0 = auth0;
    this.onChange = onChange;

    /**
     * Do an initial check to determine login state of user.
     * We store the promise of the check's results on our instance so that
     * other components can wait for the the results and take necessary action
     * (e.g. if you start the app on a auth-required view, we should see if
     * user is authenticated before booting them to the login view)
     */
    this.credentials = this.checkSession();
  }

  public initiateLogin = (redirectUri?: string) => {
    // Redirect to the Auth0 login page
    this.auth0.authorize({ state: redirectUri });
  }

  public getCredentialsFromHash = (): Promise<LoginCreds> => {
    const promise = new Promise<LoginCreds>((resolve, reject) =>
      this.auth0.parseHash((err, authResult) => {
        if (
          authResult &&
          authResult.accessToken &&
          authResult.idToken &&
          authResult.expiresIn
        ) {
          const {
            accessToken,
            idToken,
            expiresIn,
            state: redirectUrl,
          } = authResult;
          const expiresAt = JSON.stringify(
            expiresIn * 1000 + new Date().getTime()
          );
          resolve({
            accessToken,
            expiresAt,
            expiresIn,
            idToken,
            redirectUrl,
          });
        } else if (err) {
          reject(err);
        } else {
          reject(new Error(`Bad hash parse: ${authResult}`));
        }
      })
    );
    // Link up the onChange handler but don't allow it to affect the output
    promise.then(this.onChange);
    return promise;
  }

  public logout = (): void => {
    // This will log a user out of Auth0 and reload the webapp
    this.auth0.logout({ returnTo: location.origin });
  }

  public checkSession = () => {
    const promise = new Promise<LoginCreds>((resolve, reject) =>
      this.auth0.checkSession({}, (err, result: LoginCreds) =>
        err
          ? // if login_required error, user is not logged in, resolve undefined
            err.error !== 'login_required'
            ? reject(err)
            : resolve(undefined)
          : resolve(result)
      )
    );
    // Link up the onChange handler but don't allow it to affect the output
    promise.then(this.onChange);
    return promise;
  }
}

type ChangeHandler = (creds?: LoginCreds) => any;
export interface LoginCreds {
  accessToken: string;
  idToken: string;
  expiresIn: number;
  expiresAt: string;
  redirectUrl?: string;
}
interface AuthOptions {
  auth0: WebAuth;
  onChange: ChangeHandler;
}
