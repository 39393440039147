import React from 'react';
import { Col, Row } from 'reactstrap';

import { ProjectData, ProjectMetrics } from '../../../services';
import { projectDataFields } from '../../Admin/ProjectFields';
import BarChart from '../charts/BarChart';
import HorizontalBarChart from '../charts/HorizontalBarChart';
import PieChart from '../charts/PieChart';
import {
  CardGroup,
  InnerCard,
} from '../layout';

const convert = (fieldKey: keyof ConvertableMetric, data: { [key: string]: number }) =>
  projectDataFields
    .find(({key}) => key === fieldKey)!
    .fields!
    .map(({label, id}) => ({
      category: label,
      value: data[id] || 0,
    }));

export default (props: Props) => {
  const {
    landHeld = {},
    landUse = {},
    ageDistribution = {},
    landOwnership = {},
    documentationType = {},
    numberOfWomen = 0,
    numberOfMen = 0,
  } = props.project.projectData;

  return (
    <Row>
      <Col sm="12">
        <CardGroup>
          <Col sm="12" md="6" xl="4">
            <InnerCard>
              <PieChart
                donut
                showTotal
                label="Gender"
                data={[
                  {
                    category: 'Women',
                    value: numberOfWomen,
                  },
                  {
                    category: 'Men',
                    value: numberOfMen,
                  },
                ]}
              />
            </InnerCard>
          </Col>
          <Col sm="12" md="6" xl="4">
            <InnerCard>
              <BarChart
                sorted
                label="How is the land being held?"
                data={convert('landHeld', landHeld)}
                maxFields={5}
              />
            </InnerCard>
          </Col>
          <Col sm="12" md="6" xl="4">
            <InnerCard>
              <BarChart
                sorted
                label="How is the land being used?"
                data={convert('landUse', landUse)}
                maxFields={5}
              />
            </InnerCard>
          </Col>
          <Col sm="12" md="6" xl="4">
            <InnerCard>
              <HorizontalBarChart
                label="Head of Household Ages"
                data={convert('ageDistribution', ageDistribution)}
              />
            </InnerCard>
          </Col>
          <Col sm="12" md="6" xl="4">
            <InnerCard>
              <PieChart
                sorted
                label="Land Ownership"
                data={convert('landOwnership', landOwnership)}
              />
            </InnerCard>
          </Col>
          <Col sm="12" md="6" xl="4">
            <InnerCard>
              <PieChart
                sorted
                label="Land Documentation"
                data={convert('documentationType', documentationType)}
                maxFields={7}
              />
            </InnerCard>
          </Col>
        </CardGroup>
      </Col>
    </Row>
  );
};

interface Props {
  project: ProjectMetrics;
}
type ConvertableMetric = Pick<ProjectData,
  'landOwnership'|
  'ageDistribution'|
  'landUse'|
  'landHeld'|
  'documentationType'>;
