import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Alert, Col, Row } from 'reactstrap';

import { Spinner } from '../../../components/Spinner';
import { ProjectMetrics } from '../../../services';
import { Context } from '../../../state';
import { projectUseCases } from '../../Admin/ProjectFields';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderSection,
  StatsBox,
  StatsBoxHeader,
} from '../layout';
import { date } from '../numbers';
import ProjectCharts from './ProjectCharts';
import WhereStats from './WhereStats';

export const ProjectDashboard = ({
  match: {
    params: { projectId },
  },
}: Props) => {
  const [status, setStatus] = useState<Status>(undefined);
  const [project, setProject] = useState<Data>(undefined);
  const [error, setError] = useState<undefined | string>(undefined);

  const { api, setHeader } = useContext(Context);

  useEffect(
    () => {
      setStatus('LOADING');
      api.projectMetrics
        .get(projectId)
        .then((response) => {
          setStatus('LOAD_SUCCESS');
          setProject(response);
          setError(undefined);
          setHeader('Project Dashboard', response.addedOn);
        })
        .catch((err: Error) => {
          setStatus('LOAD_FAILED');
          setProject(undefined);
          setError(typeof err === 'string' ? err : err.message);
        });
    },
    [projectId]
  );

  const useCases = project
    ? projectUseCases
      .filter((useCase) => project.useCases.includes(useCase.value))
      .map((useCase) => useCase.label)
    : [];

  return (
    <Row>
      <Col sm="12" className="my-2">
        <a
          href="/"
          title="Back to Global Dashboard"
          role="button"
          aria-label="Back"
          className="btn-sm"
        >
          &lsaquo; Back to Global Dashboard
        </a>
      </Col>
      <Col sm="12" className="mb-4">
        {status === 'LOADING' && (
          <div className="text-center">
            <Spinner />
          </div>
        )}

        {status === 'LOAD_FAILED' && (
          <Alert color="danger">
            <strong>Loading project failed</strong> {error}
          </Alert>
        )}

        {project && (
          <Card>
            <CardHeaderSection>
              <Row>
                <Col lg="9" md="12">
                  {project.privacyLevel > 1 && <>
                    <CardHeader>{project.displayName}</CardHeader>
                    <p>{project.description}</p>
                  </>}
                  {useCases.length && <p>The use cases for the Cadasta Platform are {useCases.join(', ')}.</p>}
                </Col>
                <Col lg="3" md="12">
                  {project.privacyLevel > 2 && project.contact && (
                    <StatsBox>
                      <StatsBoxHeader>Contact</StatsBoxHeader>
                      <div className="stats-data">
                        {project.contact.organization}
                        <br />
                        {project.contact.email && (
                          <a href={`mailto:${project.contact.email}`}>
                            {project.contact.email}
                          </a>
                        )}
                      </div>

                      <div className="stats-data my-4">
                        {project.dateStarted && (
                          <>
                            <span className="small">
                              Initial data collection on:{' '}
                              <strong>
                                {new Date(
                                  Date.parse(project.dateStarted)
                                ).toLocaleDateString(undefined, {
                                  month: 'short',
                                  year: 'numeric',
                                })}
                              </strong>
                            </span>
                            <br />
                          </>
                        )}
                        {project.dataCurrentAsOf && (
                          <span className="small">
                            Data current as of:{' '}
                            <strong>{date(project.dataCurrentAsOf)}</strong>
                          </span>
                        )}
                      </div>
                    </StatsBox>
                  )}
                </Col>
              </Row>
            </CardHeaderSection>
            <CardBody>
              <WhereStats project={project} />
              {project.privacyLevel > 1 &&
                <ProjectCharts project={project} />
              }
            </CardBody>
          </Card>
        )}
      </Col>
    </Row>
  );
};

interface Props extends RouteComponentProps<ProjectDashboardProps> {}
interface ProjectDashboardProps {
  projectId: string;
}
type Status = undefined | 'LOADING' | 'LOAD_SUCCESS' | 'LOAD_FAILED';
type Data = undefined | ProjectMetrics;
