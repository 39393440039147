import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { Context } from '../state';

export class Logout extends React.Component {
  public static contextType = Context;
  public context!: React.ContextType<typeof Context>;

  public componentDidMount() {
    this.context.auth.logout();
  }

  public render() {
    return <Redirect to={'/'} />;
  }
}
