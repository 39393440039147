import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { Spinner } from '../components/Spinner';
import { Context } from '../state';

export class LoginStart extends React.Component<Props, State> {
  public static contextType = Context;
  /**
   * This component serves as a waiting space for the user while we check
   * the auth credentials of a user before granting access to a guarded
   * view. If a user has an active session, they will be redirected to
   * either the view that sent them here (provided that the forwarding
   * Redirect populated the state with a Location object at the "referrer"
   * key) or to the homepage. If the user has no active sesion, they are
   * sent to the external-Auth0 login page.
   */
  public readonly state: State = {};
  public context!: React.ContextType<typeof Context>;

  public componentDidMount() {
    const referrer =
      (this.props.location.state &&
        this.props.location.state.referrer &&
        this.props.location.state.referrer.pathname +
          this.props.location.state.referrer.search) ||
      '/';
    // Check to see if user is already logged-in...
    this.context.auth.credentials
      .then((credentials) =>
        credentials
          ? // If so, redirect to referrer or homepage
            this.setState({ redirect: referrer })
          : // Else, initiate login (redirecting user to Auth0)
            this.context.auth.initiateLogin(referrer)
      )
      .catch((e) => {
        if (e.error === 'unauthorized') {
          console.log(
            'Uh oh! We can\'t log you in with you current credentials.' +
              'Logging you out of Auth0',
            e
          );
          this.context.auth.logout();
        } else {
          throw e;
        }
      });
  }

  public render() {
    return (
      <div className="text-center mt-5">
        <Spinner />
        <p>
          Accessing protected route, verifying your login status...
          {this.state.redirect && <Redirect to={this.state.redirect} />}
        </p>
      </div>
    );
  }
}
interface Props extends RouteComponentProps<{}, {}, { referrer?: Location }> {}
interface State {
  redirect?: string;
}
